import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import ReactSession from '../../ReactSession';

const Footer = () => {
  const currentCompany = ReactSession.get("current_company");

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="12">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="#"
                rel="noopener noreferrer"
                target="_blank"
              >
                © {new Date().getFullYear()}{" "} {currentCompany && currentCompany.company_name && currentCompany.company_name.length > 4 ? currentCompany.company_name : 'Seemitra Clone'}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
