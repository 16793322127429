import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ProductEdit = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const {id} = useParams();

  // ATTRIBUTES PRODUCT
  const [brandId, setBrandId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [verified, setVerified] = useState(false);
  const [promo, setPromo] = useState(false);
  const [bestSeller, setBestSeller] = useState(false);
  const [weight, setWeight] = useState('');
  const [stock, setStock] = useState(0);
  const [basicPrice, setBasicPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [point, setPoint] = useState(0);
  const [cashback, setCashback] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountCustomer, setDiscountCustomer] = useState(0);
  const [subsidi, setSubsidi] = useState(0);
  const [subsidiTxt, setSubsidiTxt] = useState('');
  const [productType, setProductType] = useState('price_on_level_user');
  const [staffCommision, setStaffCommision] = useState(0);
  const [staffCommisionMitra, setStaffCommisionMitra] = useState(0);
  const [reffCommision, setReffCommision] = useState(0);
  const [merchantId, setMerchantId] = useState('');
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [imageSrc2, setImageSrc2] = useState();
  const [spinner, setSpinner] = useState(true);
  const [spinnerSave, setSpinnerSave] = useState(false);

  // COLLECTION DATA
  const [galleries, setGalleries] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productPrices, setProductPrices] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [productPriceVariants, setProductPriceVariants] = useState([]);
  const [productVariantCodes, setProductVariantCodes] = useState([]);

  // ATTRIBUTES PRICES
  const [productPriceId, setProductPriceId] = useState('');
  const [productVariantPriceId, setProductVariantPriceId] = useState('');
  const [groupUserId, setGroupUserId] = useState('');
  const [groupUserPrice, setGroupUserPrice] = useState(0);

  // ATTRIBUTES KODE VARIAN PRODUK
  const [productVariantCode, setProductVariantCode] = useState('');
  const [productVariantCodeId, setProductVariantCodeId] = useState('');
  const [productVariantCodeSearch, setProductVariantCodeSearch] = useState('');

  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreButton, setLoadMoreButton] = useState(false);

  // ATTRIBUTES VARIANT
  const [productVariantId, setProductVariantId] = useState('');
  const [variantParentId, setVariantParentId] = useState('');
  const [variantSku, setVariantSku] = useState('');
  const [variantProductId, setVariantProductId] = useState('');
  const [variantName, setVariantName] = useState('');
  const [variantStock, setVariantStock] = useState(0);
  const [variantBasicPrice, setVariantBasicPrice] = useState(0);
  const [variantSellPrice, setVariantSellPrice] = useState(0);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [visibleGroup, setVisibleGroup] = useState(false);
  const onDismissGroup = () => setVisibleGroup(false);
  const [errorMessageGroup, setErrorMessageGroup] = useState('');

  const [visibleVariant, setVisibleVariant] = useState(false);
  const onDismissVariant = () => setVisibleVariant(false);
  const [errorMessageVariant, setErrorMessageVariant] = useState('');

  const [visibleVariantPrice, setVisibleVariantPrice] = useState(false);
  const onDismissVariantPrice = () => setVisibleVariantPrice(false);
  const [errorMessageVariantPrice, setErrorMessageVariantPrice] = useState('');

  const [visibleVariantProductCode, setVisibleVariantProductCode] = useState(false);
  const onDismissVariantProductCode = () => setVisibleVariantProductCode(false);
  const [errorMessageVariantProductCode, setErrorMessageVariantProductCode] = useState('');

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setVisibleGroup(false);
    if(id){
      let dt = productPrices.filter(o => o.id === id)[0]
      if(dt){
        setProductVariantPriceId('');
        setProductPriceId(dt.id);
        setGroupUserId(dt.group_user_id);
        setGroupUserPrice(dt.sell_price);
      }else{
        setProductVariantPriceId('');
        setProductPriceId('');
        setGroupUserId('');
        setGroupUserPrice(0);
      }
    }else{
      setProductVariantPriceId('');
      setProductPriceId('');
      setGroupUserId('');
      setGroupUserPrice(0);
    }
    setModal(!modal);
  }

  const [modalVariant, setModalVariant] = useState(false);
  const toggleVariant = (variant_id) => {
    setVisibleVariant(false);
    if(variant_id){
      let dt = productVariants.filter(o => o.id === variant_id)[0]
      if(dt){
        setProductVariantId(dt.id);
        if(dt.parent_id === null){
          setVariantParentId('');
        }else{
          setVariantParentId(dt.parent_id);
        }
        setVariantSku(dt.sku);
        setVariantProductId(id);
        setVariantName(dt.variant_name);
        setVariantStock(dt.stock);
        setVariantBasicPrice(dt.basic_price);
        setVariantSellPrice(dt.sell_price);
        // setPoint(dt.point);
        // setCashback(dt.cashback);
        // setDiscount(dt.discount);
        // setDiscountCustomer(dt.discountCustomer);
        // setSubsidi(dt.subsidi);
        // setSubsidiTxt(dt.subsidi_txt);
      }else{
        setProductVariantId('');
        setVariantParentId('');
        setVariantSku(code);
        setVariantProductId(id);
        setVariantName('');
        setVariantStock(0);
        setVariantBasicPrice(0);
        setVariantSellPrice(0);
        // setPoint(0);
        // setCashback(0);
        // setDiscount(0);
        // setDiscountCustomer(0);
        // setSubsidi(0);
        // setSubsidiTxt('');
      }
    }else{
      setProductVariantId('');
      setVariantParentId('');
      setVariantSku(code);
      setVariantProductId(id);
      setVariantName('');
      setVariantStock(0);
      setVariantBasicPrice(0);
      setVariantSellPrice(0);
      // setPoint(0);
      // setCashback(0);
      // setDiscount(0);
      // setDiscountCustomer(0);
      // setSubsidi(0);
      // setSubsidiTxt('');
    }
    setModalVariant(!modalVariant);
  }

  const [modalVariantPrice, setModalVariantPrice] = useState(false);
  const toggleVariantPrice = (variant_id) => {
    setVisibleVariantPrice(false);

    if(modalVariantPrice == false){
      loadProductVariantPrices(variant_id);
      setProductVariantPriceId(variant_id);
    }else{
      setProductVariantPriceId('');
    }
    setProductPriceId('');
    setGroupUserId('');
    setGroupUserPrice(0);

    setModalVariantPrice(!modalVariantPrice);
  }

  const [modalVariantProductCode, setModalVariantProductCode] = useState(false);
  const toggleVariantProductCode = (variant_id) => {
    let _variant_id = variant_id ? variant_id : ''
    setProductVariantCodes([]);
    setOffset(1);
    setVisibleVariantProductCode(false);
    setProductVariantCode('');
    if(modalVariantProductCode == false){
      loadProductVariantCodes(id, _variant_id);
      setProductVariantCodeId(_variant_id);
    }else{
      setProductVariantCodeId('');
    }

    setModalVariantProductCode(!modalVariantProductCode);
  }

  const productTypes = [
    {
      id: 'price_on_level_user',
      name: 'Variasi Harga Hanya di Level User'
    }
    // {
    //   id: 'price_on_variant',
    //   name: 'Variasi Harga di Level User & Varian'
    // }
  ]

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = () => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('brand_id', brandId);
    fd.append('category_id', categoryId);
    fd.append('sub_category_id', subCategoryId);
    fd.append('code', code);
    fd.append('title', title);
    fd.append('description', description);
    fd.append('verified', verified);
    fd.append('promo', promo);
    fd.append('bestseller', bestSeller);
    fd.append('weight', parseInt(weight));
    fd.append('stock', stock);
    fd.append('basic_price', parseInt(basicPrice));
    fd.append('sell_price', parseInt(sellPrice));
    fd.append('product_type', productType);
    fd.append('point', point);
    fd.append('cashback', cashback);
    fd.append('discount', discount);
    fd.append('discount_customer', discountCustomer);
    fd.append('subsidi', subsidi);
    fd.append('subsidi_txt', subsidiTxt);
    fd.append('staff_commision', staffCommision);
    fd.append('staff_commision_mitra', staffCommisionMitra);
    fd.append('reff_commision', reffCommision);
    fd.append('merchant_id', merchantId);
    
    API.put(`products/${id}`, fd, config )
      .then(res => {
        alert("Perubahan Berhasil di Simpan!");
        setSpinnerSave(false);
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        setSpinnerSave(false);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
  }

  const loadData = () => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: '' }};
    API.get(`products/${id}`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)

        setBrandId(data.brand_id);
        setCategoryId(data.category_id);
        setSubCategoryId(data.sub_category_id);
        setCode(data.code);
        setTitle(data.title);
        setDescription(data.description);
        setVerified(data.verified);
        setPromo(data.promo);
        setBestSeller(data.bestseller);
        setWeight(data.weight);
        setStock(data.stock);
        setBasicPrice(data.basic_price);
        setSellPrice(data.sell_price);
        setImageSrc(data.image_url);
        setImageFile('');
        setDataLoad();
        setCategoryLoad(data.category_id);
        setProductType(data.product_type);
        setPoint(data.point);
        setCashback(data.cashback);
        setDiscount(data.discount);
        setDiscountCustomer(data.discount_customer);
        setSubsidi(data.subsidi);
        setSubsidiTxt(data.subsidi_txt);
        setStaffCommision(data.staff_commision);
        setStaffCommisionMitra(data.staff_commision_mitra);
        setReffCommision(data.reff_commision);
        setMerchantId(data.merchant_id);

        const config_gr = { headers: { Authorization: `Bearer ${token}`}, params: { pagination: false }};
        API.get(`group_users`, config_gr)
          .then(res => {
            const data = res.data.data;
            console.log(res.data)
            setGroupUsers(data);
          })

        setSpinner(false);
      })
  }

  const setDataLoad = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {pagination: false }};
    API.get(`brands`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data);
      })

    API.get(`categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCategories(data);
      })
    
    const config_gr = { headers: { Authorization: `Bearer ${token}`}, params: { pagination: false  }};
    API.get(`group_users`, config_gr)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setGroupUsers(data);
      })
  }

  const setCategoryLoad = (category_id) => {
    setCategoryId(category_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_parent_id: category_id, pagination: false }};    
    API.get(`categories`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setSubCategories(data);
      })
  }

  const loadProductPrices = (product_id) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_product_id: product_id, pagination: false }};    
    API.get(`product_prices`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setProductPrices(data);
      })
  }

  const loadProductVariants = (product_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_product_id: product_id, pagination: false, all_variant: true, include: 'product_variant_child' }};    
    API.get(`product_variants`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data);
        setProductVariants(data);
        if(data && data.length > 0){
          setStock(data.filter(d=> d.parent_id === null).reduce((a,v) =>  a = a + v.stock , 0 ));
        }
      })
  }

  const loadProductVariantPrices = (variant_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_product_variant_id: variant_id, pagination: false }};    
    API.get(`product_prices`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data);
        setProductPriceVariants(data);
      })
  }

  const loadProductVariantCodes = (product_id, product_variant_id, keyword) => {    
    setProductVariantCodeSearch(keyword);
    setOffset(1);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_product_id: product_id, by_product_variant_id: product_variant_id, include: '' }};    
    API.get(`product_variant_codes`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data);
        setProductVariantCodes(data);
        setLoadMore(false);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreProductVariantCodes = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: {page: offset_page, by_product_id: id, by_product_variant_id: productVariantCodeId, include: '' } };
    
    API.get(`product_variant_codes`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setProductVariantCodes([...productVariantCodes, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const postGroupPrice = (product_price_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('product_variant_id', productVariantPriceId);
    fd.append('group_user_id', groupUserId);
    fd.append('sell_price', parseInt(groupUserPrice));
    fd.append('product_id', id);
    if(product_price_id){
      API.put(`product_prices/${product_price_id}`, fd, config )
        .then(res => {
          const data = res.data.data;
          setVisibleGroup(false);
          loadProductPrices(id);
          toggle();
          if(data.product_variant_id){
            setVisibleVariantPrice(false);
            loadProductVariantPrices(data.product_variant_id);
            setProductVariantPriceId(data.product_variant_id);
            setProductPriceId('');
            setGroupUserId('');
            setGroupUserPrice(0);
          }
          alert("Data Berhasil di Simpan!");
        })
        .catch(error => {
          setVisibleGroup(true);
          if(error === undefined){
            setErrorMessageGroup('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessageGroup(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post(`product_prices`, fd, config )
        .then(res => {
          const data = res.data.data;
          setVisibleGroup(false);
          loadProductPrices(id);
          if(data.product_variant_id){
            setVisibleVariantPrice(false);
            loadProductVariantPrices(data.product_variant_id);
            setProductVariantPriceId(data.product_variant_id);
            setProductPriceId('');
            setGroupUserId('');
            setGroupUserPrice(0);
          }else{
            toggle();
          }
          alert("Data Berhasil di Simpan!");
        })
        .catch(error => {
          setVisibleGroup(true);
          if(error === undefined){
            setErrorMessageGroup('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessageGroup(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
  }

  const deleteGroupPrice = (product_price_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`product_prices/${product_price_id}`, config )
      .then(res => {
        const data = res.data.data;
        loadProductPrices(id);
        if(data.product_variant_id){
          loadProductVariantPrices(data.product_variant_id);
        }
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const postProductVariant = (product_variant_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData(); 
    fd.append('parent_id', variantParentId);
    fd.append('product_id', variantProductId);
    fd.append('sku', variantSku);
    fd.append('variant_name', variantName);
    fd.append('stock', parseInt(variantStock));
    fd.append('basic_price', parseInt(variantBasicPrice));
    fd.append('sell_price', parseInt(variantSellPrice));
    
    if(product_variant_id){
      API.put(`product_variants/${product_variant_id}`, fd, config )
        .then(res => {
          setVisibleVariant(false);
          loadProductVariants(id);
          toggleVariant();
          alert("Data Berhasil di Simpan!");
        })
        .catch(error => {
          setVisibleVariant(true);
          if(error === undefined){
            setErrorMessageVariant('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessageVariant(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post(`product_variants`, fd, config )
        .then(res => {
          setVisibleVariant(false);
          loadProductVariants(id);
          toggleVariant();
          alert("Data Berhasil di Simpan!");
        })
        .catch(error => {
          setVisibleVariant(true);
          if(error === undefined){
            setErrorMessageVariant('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessageVariant(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
  }

  const deleteProductVariant = (product_variant_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`product_variants/${product_variant_id}`, config )
      .then(res => {
        loadProductVariants(id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const setVariantNameLoad = (value) => {
    setVariantName(value);
    if(variantParentId){
      let parent = productVariants.filter(p => p.id === parseInt(variantParentId))[0]
      setVariantSku((code +'-'+ parent.variant_name +'-'+ value));
    }else{
      setVariantSku((code +'-'+value));
    }
  }

  const setVariantParentIdLoad = (value) => {
    setVariantParentId(value);
  }

  const loadGalleries = (product_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {product_id: product_id }};    
    API.get(`product_galleries`, config)
      .then(res => {
        const data = res.data.data;
        setGalleries(data);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const postProductGalleries = (e) => {
    const files = e.target.files[0]

    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData(); 
    fd.append('product_id', id);
    if(files){
      fd.append('image', files, files.name);
    }
    
    API.post(`product_galleries`, fd, config )
      .then(res => {
        loadGalleries(id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const deleteProductGallery = (product_gallery_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`product_galleries/${product_gallery_id}`, config )
      .then(res => {
        loadGalleries(id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const postProductVariantCode = (product_variant_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData(); 
    fd.append('code', productVariantCode);
    fd.append('product_id', id);
    fd.append('product_variant_id', product_variant_id);
    
    API.post(`product_variant_codes`, fd, config )
      .then(res => {
        setOffset(1);
        loadProductVariantCodes(id, product_variant_id);
        setProductVariantCode('');
        alert("Data Berhasil di Simpan!");
      })
      .catch(error => {
        setVisibleVariantProductCode(true)
        if(error === undefined){
          setErrorMessageVariantProductCode('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessageVariantProductCode(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const deleteProductVariantCode = (product_variant_code_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`product_variant_codes/${product_variant_code_id}`, config )
      .then(res => {
        loadProductVariantCodes(id, productVariantCodeId);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    loadData();
    if(id){
      loadProductPrices(id);
      loadProductVariants(id);
      loadGalleries(id);
    }
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Harga Level User</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visibleGroup} toggle={onDismissGroup}>
            {errorMessageGroup}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-users" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="select" value={groupUserId} onChange={e => setGroupUserId(e.target.value)}>
                  <option value=''>Pilih Level User</option>
                  { groupUsers ? (
                      groupUsers.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>{c.name}</option>
                        )
                      })
                    ) : null
                  }
                </Input>
              </InputGroup>
              <br/>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    Rp
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type='number'
                  placeholder="Harga User"
                  value={groupUserPrice} onChange={e => setGroupUserPrice(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => postGroupPrice(productPriceId)}>Simpan</Button>{' '}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalVariant} toggle={toggleVariant}>
        <ModalHeader toggle={toggleVariant}>Kelola Varian Produk</ModalHeader>
        <ModalBody>
         <Alert color="warning" isOpen={visibleVariant} toggle={onDismissVariant}>
            {errorMessageVariant}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-6'>
                  <label className='label-form-group'>Parent Varian</label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-folder" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={variantParentId} onChange={e => setVariantParentIdLoad(e.target.value)}>
                      <option value=''>Pilih Parent Varian</option>
                      { productVariants ? (
                          productVariants.filter(v => v.parent_id === null).map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.variant_name}</option>
                            )
                          })
                        ) : null
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <label className='label-form-group'>Nama Varian</label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-folder" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Varian"
                      value={variantName} onChange={e => setVariantNameLoad(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <label className='label-form-group'>Kode SKU Varian</label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-flag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kode SKU Varian"
                      value={variantSku} onChange={e => setVariantSku(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <label className='label-form-group'>Stok Varian</label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-tasks" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Stok Varian"
                      value={variantStock} onChange={e => setVariantStock(e.target.value)}
                    />
                  </InputGroup>
                </div>
                
                { (productType === 'price_on_variant' && variantParentId === '') || (productType === 'price_on_variant' && variantParentId === null) ? (
                  <>
                    {currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                      <>
                        <div className='col-6'>
                          <label className='label-form-group'>Harga HPP Varian</label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                Rp
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Harga HPP Varian"
                              value={variantBasicPrice} onChange={e => setVariantBasicPrice(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-6'>
                          <label className='label-form-group'>Harga Konsumen Varian</label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                Rp
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Harga Konsumen Varian"
                              value={variantSellPrice} onChange={e => setVariantSellPrice(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null }
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => postProductVariant(productVariantId)}>Simpan</Button>{' '}
          <Button color="secondary" onClick={(e) => toggleVariant(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      <Modal isOpen={modalVariantPrice} toggle={toggleVariantPrice}>
        <ModalHeader toggle={toggleVariantPrice}>Harga Level User</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visibleGroup} toggle={onDismissGroup}>
            {errorMessageGroup}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-6'>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-users" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={groupUserId} onChange={e => setGroupUserId(e.target.value)}>
                      <option value=''>Pilih Level User</option>
                      { groupUsers ? (
                          groupUsers.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            )
                          })
                        ) : null
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        Rp
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Harga User"
                      value={groupUserPrice} onChange={e => setGroupUserPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Button className='full-width btn-small btn-info mt-20' color="primary" onClick={e => postGroupPrice()}>Tambah</Button>{' '}
                </div>
                <div className='col-sm-12'>
                  <Table className="align-items-center table-flush mt-20" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Level User</th>
                        <th scope="col">Harga User</th>
                        <th width='100px' scope="col text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      { productPriceVariants ? (
                        productPriceVariants.map((u) => {
                          return (
                            <tr>
                              <td>{u.group_user_name}</td>
                              <td>{currencyFormat(u.sell_price)}</td>
                              <td>
                                <i className='pointer fa fa-trash' onClick={(e) => deleteGroupPrice(u.id)}></i>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i className='pointer fa fa-wrench' onClick={(e) => toggle(u.id)}></i>
                              </td>
                            </tr>
                          )
                        })
                      ) : null }
                    </tbody>
                  </Table>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleVariantPrice(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      <Modal isOpen={modalVariantProductCode} toggle={toggleVariantProductCode}>
        <ModalHeader toggle={toggleVariantProductCode}>List Kode Produk Varian</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visibleVariantProductCode} toggle={onDismissVariantProductCode}>
            {errorMessageVariantProductCode}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-sm-12'>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Masukan Kode Varian"
                      value={productVariantCode} onChange={e => setProductVariantCode(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Button className='full-width btn-small btn-info mt-20' color="primary" onClick={e => postProductVariantCode(productVariantCodeId)}>Tambah</Button>{' '}
                </div>
                <div className='col-sm-12'>
                  <Table className="align-items-center table-flush mt-20" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th width='50%' scope="col">
                          <Input
                            placeholder="Pencarian"
                            value={productVariantCodeSearch} onChange={e => loadProductVariantCodes(id, productVariantCodeId, e.target.value)}
                          />
                        </th>
                        <th>Varian</th>
                        <th width='50%' scope="col" className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      { productVariantCodes ? (
                        productVariantCodes.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td>{u.code}</td>
                              <td>{u.product_variant_name}</td>
                              <td className='text-center'>
                                <i className='pointer fa fa-trash' onClick={(e) => deleteProductVariantCode(u.id)}></i>
                              </td>
                            </tr>
                          )
                        })
                      ) : null }
                    </tbody>
                  </Table>
                </div>
                {paging ? (
                  <div class='col-sm-12'>
                    <div onClick={loadMoreProductVariantCodes} class='load-more-btn'>
                      {loadMore ? (
                        <center><Dots color='#444' /><br/></center>
                      ) : null}
                      SELANJUTNYA
                    </div>
                  </div>
                ) : null}
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleVariantProductCode(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>

        { spinner === false ? (
          <Row>
            <div className="col-md-8">
              <Card className="shadow mb-20">
                <CardHeader>
                  Pengaturan Produk
                  <i className='fa fa-list pointer' style={{float: 'right'}} onClick={(e) => toggleVariantProductCode()}></i>
                </CardHeader>
                <CardBody>
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Label className='label-form-group'>SKU Produk</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-flag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="SKU Produk"
                              value={code} onChange={e => setCode(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-sm-8'>
                          <Label className='label-form-group'>Nama Produk</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-bookmark" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Nama Produk"
                              value={title} onChange={e => setTitle(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Deskripsi Produk</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <Input
                              type="textarea"
                              placeholder="Deskripsi Produk"
                              value={description} onChange={e => setDescription(e.target.value)}
                              className='editor-text'
                            />
                          </InputGroup>
                        </div>
                        <div className='col-6'>
                          <div className='btn btn-white full-width'>
                            <Input type="checkbox" defaultChecked={promo} onChange={e => setPromo(!promo)}/> Promo
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='btn btn-white full-width'>
                            <Input type="checkbox" defaultChecked={bestSeller} onChange={e => setBestSeller(!bestSeller)}/> Bestseller
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>
                  Pengaturan Gambar Album
                </CardHeader>
                <CardBody>
                  <div className='image-galleries'>
                    <div className='image-gallery-list'>
                      <FormGroup className="form-img-select">
                        <div class='avatar-user avatar-user-sidebar'>
                          <ImageUpload
                            handleImageSelect={postProductGalleries}
                            imageSrc={imageSrc2}
                            setImageSrc={setImageSrc2}
                            style={{
                              width: 120,
                              height: 120
                            }}
                          />
                        </div>
                      </FormGroup>
                    </div>

                    { 
                      galleries.map((i) => {
                        return (
                          <div className='image-gallery-list'>
                            <div className='image-gallery-delete' onClick={(e) => deleteProductGallery(i.id)}>
                              <i className='ni ni-fat-remove'></i>
                            </div>
                            <img src={i.image_url}/>
                          </div>
                        )
                      })
                    }
                  </div>
                </CardBody>
              </Card>

              {currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                <>
                  <Card className="shadow mb-20">
                    <CardHeader>
                      Pengaturan Harga Utama
                    </CardHeader>
                    <CardBody>
                      <div className='row'>
                        <div className='col-6'>
                          <Label className='label-form-group'>Harga Modal (HPP) Utama</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                RP
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Harga Modal"
                              value={basicPrice} onChange={e => setBasicPrice(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-6'>
                          <Label className='label-form-group'>Harga Jual Mitra</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                RP
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Harga Jual Mitra"
                              value={sellPrice} onChange={e => setSellPrice(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  <Card className="shadow mb-20">
                    <CardHeader>
                      <div className='row'>
                        <div className='col-sm-12'>
                          Pengaturan Variasi Harga
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="border-0">
                      <div className='row'>
                        <div className='col-sm-9'>
                          <Input type="select" value={productType} onChange={e => setProductType(e.target.value)}>
                            <option value=''>Pilih Tipe Variasi Harga</option>
                            { productTypes ? (
                              productTypes.map((c) => {
                                return (
                                  <option key={c} value={c.id}>{c.name}</option>
                                )
                              })
                            ) : null }
                          </Input>
                        </div>
                        <div className='col-sm-3'>
                          { spinnerSave ? (
                            <Button className='full-width btn btn-info' color="primary">
                              <Dots color='#fff' />
                            </Button>
                          ) : (
                            <Button className='full-width btn btn-info' style={{background: (currentCompany?.company_color3)}} color="primary" onClick={e => actPost()}>Ubah</Button>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : null }

              <Card className="shadow mb-20">
                <CardHeader className="border-0">
                  <div className='row'>
                    <div className='col-sm-9'>
                      Pengaturan Variasi Produk
                    </div>
                    <div className='col-sm-3'>
                      { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                        <Button onClick={(e) => toggleVariant(e)} className='btn btn-info full-width' style={{background: (currentCompany?.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                      ) : null }
                    </div>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th width='100px' scope="col text-center">Action</th>
                      { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                        <>
                          { productType === 'price_on_variant' ? (
                            <th width='100px' scope="col text-center">Harga User</th>
                          ) : null }
                        </>
                      ) : null }
                      <th scope="col">SKU</th>
                      <th scope="col">Nama Varian</th>
                      <th scope="col">Stok</th>
                      { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                        <>
                          { productType === 'price_on_variant' ? (
                            <>
                              <th scope="col">Harga Modal</th>
                              <th scope="col">Harga Konsumen</th>
                            </>
                          ) : null}
                        </>
                      ) : null }
                    </tr>
                  </thead>
                  <tbody>
                    { productVariants ? (
                        productVariants.filter(o => o.parent_id === null).map((u) => {
                          return (
                            <>
                              <tr className='back-grey'>
                                <td>
                                  <i className='fa fa-wrench pointer'  onClick={(e) => toggleVariant(u.id)}></i>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <i className='fa fa-trash pointer' onClick={(e) => deleteProductVariant(u.id)}></i>
                                  {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                  <i className='fa fa-list pointer' onClick={(e) => toggleVariantProductCode(u.id)}></i> */}
                                </td>
                                { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                                  <>
                                    { productType === 'price_on_variant' ? (
                                      <td>
                                        <div className='pointer' onClick={(e) => toggleVariantPrice(u.id)}>
                                          <i className='fa fa-search'></i> LIHAT
                                        </div>
                                      </td>
                                    ) : null }
                                  </>
                                ) : null }
                                <td>{u.sku}</td>
                                <td>{u.variant_name}</td>
                                <td>{u.stock}</td>
                                { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                                  <>
                                    { productType === 'price_on_variant' ? (
                                      <>
                                        <td>{currencyFormat(u.basic_price)}</td>
                                        <td>{currencyFormat(u.sell_price)}</td>
                                      </>
                                    ) : null}
                                  </>
                                ) : null }
                              </tr>
                              {
                                u.product_variant_child ? (
                                  u.product_variant_child.map((c) => {
                                    return (
                                      <tr>
                                        <td>
                                          <i className='fa fa-wrench pointer'  onClick={(e) => toggleVariant(c.id)}></i>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <i className='fa fa-trash pointer' onClick={(e) => deleteProductVariant(c.id)}></i>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {/* <i className='fa fa-list pointer' onClick={(e) => toggleVariantProductCode(c.id)}></i> */}
                                        </td>
                                        { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                                          <>
                                            { productType === 'price_on_variant' ? (
                                              <td></td>
                                            ) : null }
                                          </>
                                        ) : null }
                                        <td>{c.sku}</td>
                                        <td>{u.variant_name} - {c.variant_name}</td>
                                        <td>{c.stock}</td>
                                        { currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                                          <>
                                            { productType === 'price_on_variant' ? (
                                              <>
                                                <td></td>
                                                <td></td>
                                              </>
                                            ) : null}
                                          </>
                                        ) : null }
                                      </tr>
                                    )
                                  })
                                ) : null
                              }
                            </>
                          )
                        })
                      ) : null
                    }
                  </tbody>
                  </Table>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow mb-20">
                <CardHeader>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='btn btn-white full-width'>
                        <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Publish
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      { spinnerSave ? (
                        <Button className='full-width' color="primary">
                          <Dots color='#fff' />
                        </Button>
                      ) : (
                        <Button className='full-width' style={{background: (currentCompany?.company_color3)}} color="primary" onClick={e => actPost()}>Simpan</Button>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <FormGroup className="form-img-select">
                        <div class='avatar-user avatar-user-sidebar'>
                          <ImageUpload
                            handleImageSelect={handleImageSelect}
                            imageSrc={imageSrc}
                            setImageSrc={setImageSrc}
                            style={{
                              width: 120,
                              height: 120
                            }}
                          />
                        </div>
                      </FormGroup>

                      <div className='row'> 
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Merek</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-archive" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="select" value={brandId} onChange={e => setBrandId(e.target.value)}>
                              <option value=''>Pilih Merek</option>
                              { brands ? (
                                  brands.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.name}</option>
                                    )
                                  })
                                ) : null
                              }
                            </Input>
                          </InputGroup>
                        </div>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Pilih Kategori</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-folder" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="select" value={categoryId} onChange={e => setCategoryLoad(e.target.value)}>
                              <option value=''>Pilih Kategori</option>
                              { categories ? (
                                  categories.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.name}</option>
                                    )
                                  })
                                ) : null
                              }
                            </Input>
                          </InputGroup>
                        </div>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Pilih Sub Kategori</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-folder" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="select" value={subCategoryId} onChange={e => setSubCategoryId(e.target.value)}>
                              <option value=''>Pilih Sub Kategori</option>
                              { subCategories ? (
                                subCategories.map((c) => {
                                  return (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                  )
                                })
                              ) : null }
                            </Input>
                          </InputGroup>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>Pengaturan Berat & Stok</CardHeader>
                <CardBody>
                  <div class='row'>
                    <div className='col-sm-12'>
                      <Label className='label-form-group'>Berat (gram)</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            Gr
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='number'
                          placeholder="Berat"
                          value={weight} onChange={e => setWeight(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <div className='col-sm-12'>
                      <Label className='label-form-group'>Stok Produk</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-tasks" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='number'
                          placeholder="Stok Produk"
                          value={stock} onChange={e => setStock(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>

              {currentUser && ((currentUser.type === 'Staff' && currentUser.role_access === 'finance') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Adminmaster' || currentUser.type === 'Company') ? (
                <>
                  <Card className="shadow mb-20">
                    <CardHeader>Pengaturan Diskon</CardHeader>
                    <CardBody>
                      <div class='row'>
                        {/* <div className='col-sm-12'>
                          <Label className='label-form-group'>Diskon Member</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                %
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Diskon Produk"
                              value={discount} onChange={e => setDiscount(e.target.value)}
                            />
                          </InputGroup>
                        </div> */}

                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Diskon</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                %
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Diskon Produk"
                              value={discountCustomer} onChange={e => setDiscountCustomer(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  {/* <Card className="shadow mb-20">
                    <CardHeader>Pengaturan Poin & Cashback</CardHeader>
                    <CardBody>
                      <div class='row'>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Poin</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Poin Produk"
                              value={point} onChange={e => setPoint(e.target.value)}
                            />
                          </InputGroup>
                        </div>

                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Cashback</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Cashback Produk"
                              value={cashback} onChange={e => setCashback(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card> */}

                  {/* <Card className="shadow mb-20">
                    <CardHeader>Pengaturan Subsidi Potongan Ongkir</CardHeader>
                    <CardBody>
                      <div class='row'>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Keterangan Subsidi</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-tags" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Keterangan Subsidi Produk"
                              value={subsidiTxt} onChange={e => setSubsidiTxt(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Nilai Subsidi</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Subsidi Produk"
                              value={subsidi} onChange={e => setSubsidi(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card> */}

                  {/* <Card className="shadow mb-20">
                    <CardHeader>Pengaturan Komisi</CardHeader>
                    <CardBody>
                      <div class='row'>
                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Komisi Closing CS (Jual Ke Konsumen)</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Nilai Komisi"
                              value={staffCommision} onChange={e => setStaffCommision(e.target.value)}
                            />
                          </InputGroup>
                        </div>

                        <div className='col-sm-12'>
                          <Label className='label-form-group'>Komisi Referral User</Label>
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                %
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              placeholder="Komisi Referral"
                              value={reffCommision} onChange={e => setReffCommision(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card> */}
                </>
              ) : null}
            </div>
          </Row>
        ) : null }
      </Container>
    </>
  );
};

export default ProductEdit;
