import React, { useEffect, useState } from 'react';
import API from '../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../ReactSession';
import { useHistory, Link } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import dateFormat from "dateformat";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function strftime(sFormat, date) {
  if (!(date instanceof Date)) date = new Date();
  var nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function() {
      return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
    },
    getThursday = function() {
      var target = new Date(date);
      target.setDate(nDate - ((nDay+6)%7) + 3);
      return target;
    },
    zeroPad = function(nNum, nPad) {
      return ((Math.pow(10, nPad) + nNum) + '').slice(1);
    };
  return sFormat.replace(/%[a-z]/gi, function(sMatch) {
    return (({
      '%a': aDays[nDay].slice(0,3),
      '%A': aDays[nDay],
      '%b': aMonths[nMonth].slice(0,3),
      '%B': aMonths[nMonth],
      '%c': date.toUTCString(),
      '%C': Math.floor(nYear/100),
      '%d': zeroPad(nDate, 2),
      '%e': nDate,
      '%F': date.toISOString().slice(0,10),
      '%G': getThursday().getFullYear(),
      '%g': (getThursday().getFullYear() + '').slice(2),
      '%H': zeroPad(nHour, 2),
      '%I': zeroPad((nHour+11)%12 + 1, 2),
      '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
      '%k': nHour,
      '%l': (nHour+11)%12 + 1,
      '%m': zeroPad(nMonth + 1, 2),
      '%n': nMonth + 1,
      '%M': zeroPad(date.getMinutes(), 2),
      '%p': (nHour<12) ? 'AM' : 'PM',
      '%P': (nHour<12) ? 'am' : 'pm',
      '%s': Math.round(date.getTime()/1000),
      '%S': zeroPad(date.getSeconds(), 2),
      '%u': nDay || 7,
      '%V': (function() {
              var target = getThursday(),
                n1stThu = target.valueOf();
              target.setMonth(0, 1);
              var nJan1 = target.getDay();
              if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7);
              return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2);
            })(),
      '%w': nDay,
      '%x': date.toLocaleDateString(),
      '%X': date.toLocaleTimeString(),
      '%y': (nYear + '').slice(2),
      '%Y': nYear,
      '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
      '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
    }[sMatch] || '') + '') || sMatch;
  });
}

const Index = (props) => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [report, setReport] = useState({});
  const [report2, setReport2] = useState({});
  const [reportChartDaily, setReportChartDaily] = useState([]);
  const [reportChartMonthly, setReportChartMonthly] = useState([]);
  const [reportSpending, setReportSpending] = useState({});
  const [reportSpendingClaims, setReportSpendingClaims] = useState({});
  const [reportVirtuals, setReportVirtuals] = useState({});
  const [reportVirtualOutside, setReportVirtualOutside] = useState({});
  const [reportUser, setReportUser] = useState({});
  const [reportPurchase, setReportPurchase] = useState({});

  const [activeNav, setActiveNav] = useState(1);
  const [chartData, setChartData] = useState("data1");

  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [loadMore, setLoadMore] = useState(true);

  const [offsetMonthly, setOffsetMonthly] = useState(1);
  const [pagingMonthly, setPagingMonthly] = useState(true);
  const [loadMoreMonthly, setLoadMoreMonthly] = useState(true);

  const [spinnerOrderDaily, setSpinnerOrderDaily] = useState(true);
  const [spinnerOrderMonthly, setSpinnerOrderMonthly] = useState(true);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartData("data" + index);
  };

  const loadData = (start_date, end_date) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status_payment: 'paid', category_date: 'paid_date', start_at: start_date, end_at: end_date}};

    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      }).catch(error => {
        console.log(error.response);
      })
    
    const config3 = { headers: { Authorization: `Bearer ${token}`}, params: {by_category_status: 'Repeat Order', by_status_payment: 'paid', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
    API.get(`report_user`, config3)
      .then(res => {
        const data = res.data.report;
        setReport2(data)
      })

    API.get(`spending_single_reports`, config)
      .then(res => {
        const data2 = res.data.report;
        setReportSpending(data2);
      }).catch(error => {
        console.log(error.response);
      })

    API.get(`spending_single_claim_reports`, config)
      .then(res => {
        const data3 = res.data.report;
        setReportSpendingClaims(data3);
      }).catch(error => {
        console.log(error.response);
      })

    API.get(`virtual_reports`, config)
      .then(res => {
        const data4 = res.data.report;
        setReportVirtuals(data4);
      }).catch(error => {
        console.log(error.response);
      })

    API.get(`user_reports`, config)
      .then(res => {
        const data5 = res.data.report;
        setReportUser(data5);
      }).catch(error => {
        console.log(error.response);
      })

    API.get(`purchase_single_reports`, config)
      .then(res => {
        const data6 = res.data.report;
        setReportPurchase(data6);
      }).catch(error => {
        console.log(error.response);
      })
    
    API.get(`virtual_outside`, config)
      .then(res => {
        const data7 = res.data.report;
        setReportVirtualOutside(data7);
      }).catch(error => {
        console.log(error.response);
      })

    setOffset(1);
    setOffsetMonthly(1);
    loadChartMonthly(start_date, end_date);
    loadChartDaily(start_date, end_date);
  }

  const loadMoreData = () => {
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, category: 'group_by_daily_order', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate}};
    API.get(`report_chart`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.report;
        setReportChartDaily([...reportChartDaily, ...data2]);
        if(res.data.total_pages === offset_page){
          setPaging(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadMoreDataMonthly = () => {
    setLoadMoreMonthly(true);
    setOffsetMonthly(offsetMonthly+1)
    let offset_page = offsetMonthly+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {page: offset_page, category: 'group_by_monthly_order', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate}};
    API.get(`report_chart`, config)
      .then(res => {
        setLoadMoreMonthly(false);
        const data2 = res.data.report;
        setReportChartMonthly([...reportChartMonthly, ...data2]);
        if(res.data.total_pages === offset_page){
          setPagingMonthly(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(searchStartDate, end_date);
  }

  const loadChartDaily = (start_date, end_date) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {category: 'group_by_daily_order', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderDaily(false);
        const data = res.data.report;
        setReportChartDaily(data);
        setLoadMore(false);
        if(res.data.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMonthly = (start_date, end_date) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {category: 'group_by_monthly_order', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMonthly(false);
        const data = res.data.report;
        setReportChartMonthly(data);
        setLoadMoreMonthly(false);
        if(res.data.total_pages <= 1){
          setPagingMonthly(false);
          console.log('No paging')
        }else{
          setPagingMonthly(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  let chartOrderCount = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data && data.datasets && data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
      datasets: [
        {
          label: "Sales",
          data: reportChartMonthly.map((d) => d.count).reverse(),
          maxBarThickness: 10,
        },
      ],
    },
  };

  let chartOrderSum = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return numberFormat(value);
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
  
            if (data && data.datasets && data.datasets.length > 1) {
              content += label;
            }
  
            content += numberFormat(yLabel);
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: reportChartDaily.map((d) => d.date),
        datasets: [
          {
            label: "Performance",
            data: reportChartDaily.map((d) => (currentUser && currentUser.type === 'Merchant' ? d.basic_price : (parseInt(d.store_price)-parseInt(d.virtual_spendings)))),
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
        datasets: [
          {
            label: "Performance",
            data: reportChartMonthly.map((d) => (currentUser && currentUser.type === 'Merchant' ? d.basic_price : (parseInt(d.store_price)-parseInt(d.virtual_spendings)))).reverse(),
          },
        ],
      };
    },
  };
  

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay = strftime('%Y-%m-%d', firstDay);
    setSearchStartDate(firstDay);
    loadData(firstDay);
  },[])

  return (
    <>
      <div className="header pb-7 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body" className='box-dashboard'>
            <Row>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SALDO PENGGUNA DILUAR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {reportVirtualOutside.virtual_money ? numberFormat(reportVirtualOutside.virtual_money) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          POIN PENGGUNA DILUAR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {reportVirtualOutside.virtual_point ? numberFormat(reportVirtualOutside.virtual_point) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            { currentUser && currentUser.role_access && (currentUser.role_access === 'cs') ? (
                <Row>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              USER
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.count_order_members ? numberFormat(report.count_order_members) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.count_order_members ? numberFormat(report.count_order_members-report2.count_order_members) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.count_order_members ? numberFormat(report2.count_order_members) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PESANAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_count ? numberFormat(report.order_count-report2.order_count) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_count ? numberFormat(report2.order_count) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              OMSET
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat(report.order_store) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_store ? numberFormat(report.order_store-report2.order_store) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_store ? numberFormat(report2.order_store) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              ONGKIR
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_shipping ? numberFormat(report.order_shipping+report.order_shipping_cod) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_shipping ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))-(parseInt(report2.order_shipping)+parseInt(report2.order_shipping_cod))) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_shipping ? numberFormat(parseInt(report2.order_shipping)+parseInt(report2.order_shipping_cod)) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              SUBSIDI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.subsidi ? numberFormat(report.subsidi-report2.subsidi) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.subsidi ? numberFormat(report2.subsidi) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              KOMISI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.staff_commision ? numberFormat(parseInt(report.staff_commision) + parseInt(report2.staff_commision_repeat_order)) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.staff_commision ? numberFormat(report.staff_commision) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.staff_commision_repeat_order ? numberFormat(report2.staff_commision_repeat_order) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : currentUser && currentUser.role_access && (currentUser.role_access === 'admin') ? (
                <Row>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              USER
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.count_order_members ? numberFormat(report.count_order_members) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.count_order_members ? numberFormat(report.count_order_members-report2.count_order_members) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.count_order_members ? numberFormat(report2.count_order_members) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PESANAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_count ? numberFormat(report.order_count-report2.order_count) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_count ? numberFormat(report2.order_count) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              OMSET
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat((parseInt(report.order_store)-parseInt(report.virtual_spendings))+parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{report.order_store ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              BELANJA MODAL
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {reportPurchase.total ? numberFormat(reportPurchase.total) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{reportPurchase.bill_price ? numberFormat(reportPurchase.bill_price) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{reportPurchase.shipping_price ? numberFormat(reportPurchase.shipping_price) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              SUBSIDI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.subsidi ? numberFormat(report.subsidi-report2.subsidi) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.subsidi ? numberFormat(report2.subsidi) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              MARGIN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)+parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)-parseInt(reportPurchase.total)-parseInt(report.subsidi)) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)-parseInt(reportPurchase.bill_price)) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{report.order_store ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)-parseInt(reportPurchase.shipping_price)-parseInt(report.subsidi)) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PENGELUARAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat((parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)+parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)))
                              }
                            </span>
                            <div>
                              Oprasional <b>{numberFormat(parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0))}</b>
                              <br/>
                              Klaim Saldo <b>{numberFormat(parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0))}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PROFIT
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat(
                                  (
                                    (parseInt(report.order_store ? report.order_store : 0)+parseInt(report.order_shipping ? report.order_shipping : 0)+parseInt(report.order_shipping_cod ? report.order_shipping_cod : 0))
                                    -parseInt(reportPurchase.total ? reportPurchase.total : 0)
                                    -parseInt(report.subsidi ? report.subsidi : 0)
                                    -parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)
                                    -parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)
                                    -parseInt(report.virtual_spendings ? report.virtual_spendings : 0)
                                    +parseInt(reportVirtuals.incomes ? reportVirtuals.incomes : 0)
                                    +parseInt(reportUser.user_reports ? reportUser.user_reports : 0)
                                  )
                                )
                              }
                            </span>
                            <div>
                              Omset Penjualan &nbsp;
                              <b>
                                {
                                  numberFormat(
                                    (
                                      (parseInt(report.order_store ? report.order_store : 0)+parseInt(report.order_shipping ? report.order_shipping : 0)+parseInt(report.order_shipping_cod ? report.order_shipping_cod : 0))
                                      -parseInt(reportPurchase.total ? reportPurchase.total : 0)
                                      -parseInt(report.subsidi ? report.subsidi : 0)
                                      -parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)
                                      -parseInt(report.virtual_spendings ? report.virtual_spendings : 0)
                                      -parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)
                                    )
                                  )
                                }
                              </b>
                              <br/>
                              Pendaftaran <b>{reportUser.user_reports ? numberFormat(reportUser.user_reports) : numberFormat(0)}</b>
                              {
                                reportVirtuals.incomes && reportVirtuals.incomes > 0 ? (
                                  <>
                                    <br/>
                                    Saldo Masuk <b>{reportVirtuals.incomes ? numberFormat(reportVirtuals.incomes) : numberFormat(0)}</b>
                                  </>
                                ) : null
                              }
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : currentUser && currentUser.type === 'Merchant' ? (
                <Row>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              OMSET
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_basic ? numberFormat(report.order_basic) : numberFormat(0)}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              ONGKIR
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))) : numberFormat(0)}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? (
                <Row>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              OMSET
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat(report.order_store) : numberFormat(0)}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="6">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              ONGKIR
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))) : numberFormat(0)}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              USER
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.count_order_members ? numberFormat(report.count_order_members) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.count_order_members ? numberFormat(report.count_order_members-report2.count_order_members) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.count_order_members ? numberFormat(report2.count_order_members) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PESANAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_count ? numberFormat(report.order_count-report2.order_count) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_count ? numberFormat(report2.order_count) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              OMSET
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat((parseInt(report.order_store)-parseInt(report.virtual_spendings))+parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{report.order_store ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              BELANJA MODAL
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {reportPurchase.total ? numberFormat(reportPurchase.total) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{reportPurchase.bill_price ? numberFormat(reportPurchase.bill_price) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{reportPurchase.shipping_price ? numberFormat(reportPurchase.shipping_price) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              SUBSIDI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.subsidi ? numberFormat(report.subsidi-report2.subsidi) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.subsidi ? numberFormat(report2.subsidi) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              MARGIN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)+parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)-parseInt(reportPurchase.total)-parseInt(report.subsidi)) : numberFormat(0)}
                            </span>
                            <div>
                              Barang <b>{report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)-parseInt(reportPurchase.bill_price)) : numberFormat(0)}</b>
                              <br/>
                              Ongkir <b>{report.order_store ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)-parseInt(reportPurchase.shipping_price)-parseInt(report.subsidi)) : numberFormat(0)}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PENGELUARAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat((parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)+parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)))
                              }
                            </span>
                            <div>
                              Oprasional <b>{numberFormat(parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0))}</b>
                              <br/>
                              Klaim Saldo <b>{numberFormat(parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0))}</b>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PROFIT
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat(
                                  (
                                    (parseInt(report.order_store ? report.order_store : 0)+parseInt(report.order_shipping ? report.order_shipping : 0)+parseInt(report.order_shipping_cod ? report.order_shipping_cod : 0))
                                    -parseInt(reportPurchase.total ? reportPurchase.total : 0)
                                    -parseInt(report.subsidi ? report.subsidi : 0)
                                    -parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)
                                    -parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)
                                    -parseInt(report.virtual_spendings ? report.virtual_spendings : 0)
                                    +parseInt(reportVirtuals.incomes ? reportVirtuals.incomes : 0)
                                    +parseInt(reportUser.user_reports ? reportUser.user_reports : 0)
                                  )
                                )
                              }
                            </span>
                            <div>
                              Omset Penjualan &nbsp;
                              <b>
                                {
                                  numberFormat(
                                    (
                                      (parseInt(report.order_store ? report.order_store : 0)
                                      +parseInt(report.order_shipping ? report.order_shipping : 0)
                                      +parseInt(report.order_shipping_cod ? report.order_shipping_cod : 0))
                                      -parseInt(reportPurchase.total ? reportPurchase.total : 0)
                                      -parseInt(report.subsidi ? report.subsidi : 0)
                                      -parseInt(reportSpending.spending_price ? reportSpending.spending_price : 0)
                                      -parseInt(report.virtual_spendings ? report.virtual_spendings : 0)
                                      -parseInt(reportSpendingClaims.spending_price ? reportSpendingClaims.spending_price : 0)
                                    )
                                  )
                                }
                              </b>
                              <br/>
                              Pendaftaran <b>{reportUser.user_reports ? numberFormat(reportUser.user_reports) : numberFormat(0)}</b>
                              {
                                reportVirtuals.incomes && reportVirtuals.incomes > 0 ? (
                                  <>
                                    <br/>
                                    Saldo Masuk <b>{reportVirtuals.incomes ? numberFormat(reportVirtuals.incomes) : numberFormat(0)}</b>
                                  </>
                                ) : null
                              }
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                )}
          </div>
        </Container>
      </div>

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="bg-gradient-default shadow mb-20">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col-sm-4">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="mb-0">Omset</h2>
                  </div>
                  <div className="col-sm-8">
                    <Row>
                      <div className='col-sm-6 datepicker-chart'>
                        <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                      </div>
                      <div className='col-sm-6 datepicker-chart'>
                        <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <Nav className="justify-content-end" pills>
                  <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 1,
                      })}
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 1)}
                    >
                      <span className="d-none d-md-block">Harian</span>
                      <span className="d-md-none">Hari</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 2,
                      })}
                      data-toggle="tab"
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 2)}
                    >
                      <span className="d-none d-md-block">Bulanan</span>
                      <span className="d-md-none">Bulan</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="chart">
                  <Line
                    data={chartOrderSum[chartData]}
                    options={chartOrderSum.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow mb-20">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Statistik Pesanan Sukses</h2>
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performa Bulan Terakhir
                    </h6>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart2">
                  <Bar
                    data={chartOrderCount.data}
                    options={chartOrderCount.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Performa Omset Terakhir</h3>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Harian</span>
                          <span className="d-md-none">Hari</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Bulanan</span>
                          <span className="d-md-none">Bulan</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>

              {
                activeNav === 2 ? (
                  <>
                    {
                      spinnerOrderMonthly ? (
                        <center>
                          <div className='loadLoading'><Dots color='#444' /></div>
                        </center>
                      ) : (
                        <>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Bulan</th>
                                <th scope="col">Pesanan</th>
                                <th scope="col">RO USER</th>
                                <th scope="col">Omset Penjualan</th>
                                { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                  <th scope="col">Perkiraan HPP</th>
                                ) }
                                <th scope="col">Subsidi</th>
                                { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                  <th scope="col">Margin</th>
                                ) }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                reportChartMonthly.map((u) => {
                                  return(
                                    <tr key={u.month}>
                                      <td>{dateFormat(u.month, "mmmm, yyyy")}</td>
                                      <td>{numberFormat(u.count)}</td>
                                      <td>{numberFormat(u.count_order_members)} / {numberFormat(u.count_members)}</td>
                                      <td>+{numberFormat(currentUser.type === 'Merchant' ? u.basic_price : (parseInt(u.store_price)-parseInt(u.virtual_spendings)) )}</td>
                                      { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                        <td>-{numberFormat(u.basic_price)}</td>
                                      ) }
                                      <td>-{numberFormat(u.subsidi)}</td>
                                      { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                        <td>{numberFormat(parseInt(u.margin_price)-parseInt(u.virtual_spendings)-parseInt(u.subsidi))}</td>
                                      ) }
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>

                          {pagingMonthly ? (
                            <div class='col'>
                              <div onClick={loadMoreDataMonthly} class='load-more-btn'>
                                {loadMoreMonthly ? (
                                  <center><Dots color='#444' /><br/></center>
                                ) : null}
                                SELANJUTNYA
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      spinnerOrderDaily ? (
                        <center>
                          <div className='loadLoading'><Dots color='#444' /></div>
                        </center>
                      ) : (
                        <>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Tangal</th>
                                <th scope="col">Pesanan</th>
                                <th scope="col">Omset Penjualan</th>
                                { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                  <th scope="col">Perkiraan HPP</th>
                                ) }
                                <th scope="col">Subsidi</th>
                                { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                  <th scope="col">Margin</th>
                                ) }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                reportChartDaily.map((u) => {
                                  return(
                                    <tr key={u.date}>
                                      <td>{dateFormat(u.date, "dd-mm-yyyy")}</td>
                                      <td>{numberFormat(u.count)}</td>
                                      <td>+{numberFormat(currentUser.type === 'Merchant' ? u.basic_price : (parseInt(u.store_price)-parseInt(u.virtual_spendings)) )}</td>
                                      { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                        <td>-{numberFormat(u.basic_price)}</td>
                                      ) }
                                      <td>-{numberFormat(u.subsidi)}</td>
                                      { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' || currentUser.type === 'Merchant' ? null : (
                                        <td>{numberFormat(parseInt(u.store_price)-parseInt(u.virtual_spendings)-parseInt(u.basic_price)-parseInt(u.subsidi))}</td>
                                      ) }
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>

                          {paging ? (
                            <div class='col'>
                              <div onClick={loadMoreData} class='load-more-btn'>
                                {loadMore ? (
                                  <center><Dots color='#444' /><br/></center>
                                ) : null}
                                SELANJUTNYA
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                    }
                  </>
                )
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
