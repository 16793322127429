import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, Link, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import dateFormat from "dateformat";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label, 
  Col,
  CardBody
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ReportMembers = (props) => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const {category} = useParams();
  const currentPage = window.location.pathname.split('/')[2]

  const [search, setSearch] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [level, setLevel] = useState('');

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [agents, setAgents] = useState([]);

  const [provincesOld, setProvincesOld] = useState([]);
  const [citiesOld, setCitiesOld] = useState([]);

  const [spinnerProvince, setSpinnerProvince] = useState(false);
  const [spinnerCity, setSpinnerCity] = useState(false);
  const [spinnerAgent, setSpinnerAgent] = useState(false);

  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [loadMore, setLoadMore] = useState(false);

  const [id, setId] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkMarketplace1, setLinkMarketplace1] = useState('');
  const [lastFollowup, setLastFollowup] = useState('');
  const [lastFollowupDate, setLastFollowupDate] = useState('');
  const [lastFollowupNote, setLastFollowupNote] = useState('');
  const [verified, setVerified] = useState(false);
  const [membership, setMembership] = useState(false);
  const [affiliate, setAffiliate] = useState(false);
  const [promotion, setPromotion] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [spinnerSave, setSpinnerSave] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = agents.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setUsername(dt.username);
        setEmail(dt.email);
        setFullName(dt.full_name);
        setPhone1(dt.phone1);
        setInstagram(dt.instagram);
        setLinkMarketplace1(dt.link_marketplace1);
        setLastFollowup(dt.last_followup);
        setLastFollowupDate(dt.last_followup_date);
        setLastFollowupNote(dt.last_followup_note);
        setMembership(dt.membership);
        setVerified(dt.verified);
        setAffiliate(dt.affiliate);
        setPromotion(dt.promotion);
      }
    }
    setModal(!modal);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('username', username);
    fd.append('email', email);
    fd.append('full_name', fullName);
    fd.append('phone1', phone1);
    fd.append('instagram', instagram);
    fd.append('link_marketplace1', linkMarketplace1);
    fd.append('last_followup', lastFollowup);
    fd.append('last_followup_date', lastFollowupDate);
    fd.append('last_followup_note', lastFollowupNote);
    fd.append('membership', membership);
    fd.append('verified', verified);
    fd.append('affiliate', affiliate);
    fd.append('promotion', promotion);
    
    if(id){
      API.put(`users/${id}`, fd, config )
        .then(res => {
          setSpinnerSave(false);
          const dt = res.data.data;
          const oldData = agents;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setAgents(oldData);
          }
          // loadAgents(data.city);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (level) => {
    setSpinnerProvince(true)
    const config = { headers: { Authorization: `Bearer ${token}` }, params: {by_level: level, access_with_account: true, secret_key: '6b4fb09b2f33dfad471463e46180e6606f403c8c4aeae09b3f455ae4f809c2370043863cf59a0838b7a2f270f9a8e9cb1c04cba95ec98812edfaf7d1e3d4976a'} };
    API.get(`province_members`, config)
      .then(res => {
        const data = res.data.report;
        setSpinnerProvince(false)
        setProvinces(data);
        setProvincesOld(data);
      })
    
  }

  const loadCity = (province) => {
    setCities([]);
    setCitiesOld([]);
    setSpinnerCity(true)
    const config = { headers: { Authorization: `Bearer ${token}` }, params: {by_province: province, by_level: level, access_with_account: true, secret_key: '6b4fb09b2f33dfad471463e46180e6606f403c8c4aeae09b3f455ae4f809c2370043863cf59a0838b7a2f270f9a8e9cb1c04cba95ec98812edfaf7d1e3d4976a'} };
    setProvince(province);
    API.get(`city_members/${province}`, config)
      .then(res => {
        const data = res.data.report;
        setCities(data);
        setCitiesOld(data);
        setSpinnerCity(false)
      })
    
  }

  const loadAgents = (city) => {
    setAgents([]);
    setSpinnerAgent(true)
    const config = { headers: { Authorization: `Bearer ${token}` }, params: {by_city: city, by_level: level, access_with_account: true, secret_key: '6b4fb09b2f33dfad471463e46180e6606f403c8c4aeae09b3f455ae4f809c2370043863cf59a0838b7a2f270f9a8e9cb1c04cba95ec98812edfaf7d1e3d4976a'} };
    setCity(city);
    API.get(`region_members`, config)
      .then(res => {
        const data = res.data.data;
        setAgents(data);
        setSpinnerAgent(false)
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        setSpinnerAgent(false);
        console.log(error.response);
      })
  }

  const loadMoreData = () => {
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, by_level: level, by_city: city, include: '', access_with_account: true, secret_key: '6b4fb09b2f33dfad471463e46180e6606f403c8c4aeae09b3f455ae4f809c2370043863cf59a0838b7a2f270f9a8e9cb1c04cba95ec98812edfaf7d1e3d4976a'} };
    
    API.get(`region_members`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setAgents([...agents, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }
      })
  }

  const updateSearch = (s) => {
    setProvince('')
    setSpinnerProvince(false)
    if(s === '' ){
      setSearch('');
    }else{
      setSearch(s);
    }
    let _provinces = provincesOld.filter((p) => p.name.toLowerCase().includes(s))
    setProvinces(_provinces);
  }

  const updateSearchCity = (s) => {
    if(province === ''){
      setSearchCity('');
      alert('Untuk Pencarian Kota, Silahkan Klik/Pilih Salah Satu Provinsi Dibawah Terlebih Dahulu')
    }
    setCity('')
    setSpinnerCity(false)
    if(s === '' ){
      setSearchCity('');
    }else{
      setSearchCity(s);
    }
    let _cities = citiesOld.filter((p) => p.name.toLowerCase().includes(s))
    setCities(_cities);
  }

  const updateSearchLevel = (s) => {
    setOffset(1);
    setLevel('');

    setAgents([]);
    setCities([]);
    
    setCity('');
    setProvince('');

    setSpinnerProvince(false);
    setSpinnerCity(false)
    setSpinnerAgent(false);
    if(s === '' ){
      setLevel('');
    }else{
      setLevel(s);
    }
    loadData(s);
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    loadData(level);

  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola User {fullName}</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Instagram</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        @
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="@nama_instagram"
                      value={instagram} onChange={e => setInstagram(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Link Marketplace</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-globe" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="https://shopee.com/nama_toko"
                      value={linkMarketplace1} onChange={e => setLinkMarketplace1(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Status FollowUp</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={lastFollowup} onChange={e => setLastFollowup(e.target.value)}>
                      <option value=''>Status FollowUp</option>
                      <option value='No Tidak Bisa di Hubungi'>No Tidak Bisa di Hubungi</option>
                      <option value='Susah Komunikasi'>Susah Komunikasi</option>
                      <option value='Kendala Berjualan'>Kendala Berjualan</option>
                      <option value='Penjualan Aktif'>Penjualan Aktif</option>
                      <option value='Penjualan Meningkat'>Penjualan Meningkat</option>
                      <option value='Sudah Tidak Jualan'>Sudah Tidak Jualan</option>
                      <option value='Tidak Minat Jadi User'>Tidak Minat Jadi User</option>
                      <option value='Lain-Lain'>Lain-Lain</option>
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>FollowUp Terakhir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker2" value={lastFollowupDate} onChange={e => setLastFollowupDate(e)} />
                  </FormGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>FollowUp Subyek</Label>
                  <Input type="select" value={lastFollowupNote} onChange={e => setLastFollowupNote(e.target.value)}>
                    <option value=''>Jenis Subyek</option>
                    <option value='Sampaikan Promo'>Sampaikan Promo</option>
                    <option value='Penawaran Naik Level'>Penawaran Naik Level</option>
                    <option value='Curhat Lewat Chat'>Curhat Lewat Chat</option>
                    <option value='Curhat Lewat Telpon'>Curhat Lewat Telpon</option>
                    <option value='Bantu Strategi Jualan'>Bantu Strategi Jualan</option>
                    <option value='Broadcast Pengumuman'>Broadcast Pengumuman</option>
                    <option value='Subyek Lain-Lain'>Subyek Lain-Lain</option>
                  </Input>
                </div>
                {/* <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={membership} onChange={e => setMembership(!membership)}/> Keanggotaan
                    </Label>
                  </FormGroup>
                </div> */}
                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={affiliate} onChange={e => setAffiliate(!affiliate)}/> Referral
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={promotion} onChange={e => setPromotion(!promotion)}/> Dipromosikan
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
      </div>

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Penyebaran Domisili User</h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <br/>
                <div className='row'>
                  <div className='col m4 s4'>
                    <input type='text' placeholder='Level User' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                  </div>
                  <div className='col m4 s4'>
                    <input type='text' placeholder='Cari Provinsi' className='form-control' value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col m4 s4'>
                    <input type='text' placeholder='Cari Kota' className='form-control' value={searchCity} onChange={e => updateSearchCity(e.target.value)}/>
                  </div>
                </div>
                <br/>
                <ul class="list-provinces">
                  { provinces && provinces.length > 0 ? (
                    provinces.map((p) => {
                      return (
                        <li>
                          <div class="collapsible-header" onClick={(e) => loadCity(p.name)}><b>{p.name} ({p.count})</b></div>
                          { cities && cities.length > 0 && province === p.name ? (
                            <div class="collapsible-body">
                              {
                                <>
                                  { 
                                    cities.map((p) => {
                                      return (
                                        <li>
                                          <div class="collapsible-header" onClick={(e) => loadAgents(p.name)}><b>{p.name} ({p.count})</b></div>
                                          <div class="collapsible-body">
                                            { agents && agents.length > 0 && city === p.name ? (
                                              <div class="collapsible-body">
                                                {
                                                  <>
                                                    { 
                                                      agents.map((p) => {
                                                        return (
                                                          <li>
                                                            <div class="collapsible-header">
                                                              <div className='row'>
                                                                <div className='col m6'>
                                                                  <Link to={`/admin/member/${p.id}`}>
                                                                    <b>{p.full_name} ({p.group_user_name ? p.group_user_name : 'Customer'})</b>
                                                                  </Link>
                                                                  <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                      className="btn-icon-only text-light"
                                                                      href="#pablo"
                                                                      role="button"
                                                                      size="sm"
                                                                      color=""
                                                                      onClick={(e) => e.preventDefault()}
                                                                    >
                                                                      <i className="fas fa-ellipsis-v" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                                      <DropdownItem onClick={(e) => toggle(p.id)}>
                                                                        Ubah
                                                                      </DropdownItem>
                                                                    </DropdownMenu>
                                                                  </UncontrolledDropdown>
                                                                  <br/>
                                                                  {p.district}, {p.city}, {p.province}
                                                                  <br/>
                                                                  {p.address}
                                                                  <br/>
                                                                  <br/>
                                                                  Terdaftar <b>{p.created_at}</b>
                                                                  <br/>
                                                                  Pembelian Awal <b>{p.first_order_date ? p.first_order_date : '-'}</b> Sebesar <b>{numberFormat(p.first_order_value)}</b>
                                                                  <br/>
                                                                  Pembelian Terakhir <b>{p.last_order_date ? p.last_order_date : '-'}</b>
                                                                  <br/>
                                                                  FollowUp Terakhir <b>{p.last_followup ? p.last_followup : '-'}</b>
                                                                  <br/>
                                                                  Subyek Pesan <b>{p.last_followup_note ? p.last_followup_note : '-'}</b>
                                                                  <br/>
                                                                  {p.staff_id ? (
                                                                    <>
                                                                      CS <b>{p.staff_id}</b>
                                                                    </>
                                                                  ) : null }
                                                                </div>
                                                                <div className='col m6'>
                                                                  <div style={{marginBottom:5}}>Instagram <b>{p.instagram}</b></div>
                                                                  <div style={{marginBottom:5}}>Marketplace <b>{p.link_marketplace1}</b></div>
                                                                  <div style={{marginBottom:5}}>WhatsApp <b>{p.phone1}</b></div>
                                                                  <a href={'https://api.whatsapp.com/send?phone='+p.phone1+'&text=Hi%20ka%20'+p.full_name} className='btn btn-sm btn-success' style={{width: 200}}>Hubungi Via WA</a>
                                                                </div>
                                                              </div>
                                                              
                                                            </div>
                                                          </li>
                                                        )
                                                      })
                                                    }

                                                    {paging ? (
                                                      <div onClick={loadMoreData} class='load-more-btn'>
                                                        {loadMore ? (
                                                          <center><Dots color='#444' /><br/></center>
                                                        ) : null}
                                                        SELANJUTNYA
                                                      </div>
                                                    ) : null}
                                                  </>
                                                }
                                              </div>
                                            ) : city === p.name ? (
                                              <center>
                                                <Dots color='#444' />
                                              </center>
                                            ) : null}
                                          </div>
                                        </li>
                                      )
                                    })
                                  }
                                </>
                              }
                            </div>
                          ) : province === p.name && spinnerCity ? (
                            <center>
                              <Dots color='#444' />
                            </center>
                          ) : null}
                        </li>
                      )
                    })
                  ) : spinnerProvince ? (
                    <center>
                      <Dots color='#444' />
                    </center>
                  ) : (
                    <center>Tidak Ditemukan</center>
                  )
                  }
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportMembers;
