import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { ExitStatus } from 'typescript';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const MemberIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [lastPackageId, setLastPackageId] = useState('');
  const [idCardNumber, setIdCardNumber] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [editAddress, setEditAddress] = useState(false);
  const [verified, setVerified] = useState(false);
  const [membership, setMembership] = useState(false);
  const [verifiedIdCard, setVerifiedIdCard] = useState(false);
  const [domain, setDomain] = useState('');
  const [staffId, setStaffId] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [reffCode, setReffCode] = useState('');
  const [affiliate, setAffiliate] = useState(false);
  const [instagram, setInstagram] = useState('');
  const [linkMarketplace1, setLinkMarketplace1] = useState('');
  const [promotion, setPromotion] = useState(false);
  const [firstOrder, setFirstOrder] = useState(0);
  const [lastFollowup, setLastFollowup] = useState('');
  const [lastFollowupDate, setLastFollowupDate] = useState('');
  const [lastFollowupNote, setLastFollowupNote] = useState('');
  const [paymentBankNumber, setPaymentBankNumber] = useState('');
  const [paymentBankName, setPaymentBankName] = useState('');
  const [paymentBankKcp, setPaymentBankKcp] = useState('');
  const [registerMemberPrice, setRegisterMemberPrice] = useState(100000);
  const [levelType, setLevelType] = useState('');

  const [level, setLevel] = useState('');
  const [byStaffId, setByStaffId] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [byStatusVerified, setByStatusVerified] = useState('');
  const [uniqCode, setByUniqCode] = useState('');
  const [searchLevelType, setSearchLevelType] = useState('');

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();

  const [idCardFile, setIdCardFile] = useState();
  const [idCardSrc, setIdCardSrc] = useState();
  
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [customerServices, setCustomerServices] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [spinnerSave, setSpinnerSave] = useState(false);
  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const navCollection = [
    {'name':'Semua', 'value':''},
    {'name':'User Aktif', 'value': 'mitra_active'},
    {'name':'User Tidak Aktif', 'value':'mitra_nonactive'},
    {'name':'Konsumen Aktif', 'value': 'customer_active'},
    {'name':'Konsumen Tidak Aktif', 'value':'customer_nonactive'}
  ]

  const levelTypes = [
    {'name':'Exe Direktur', 'value':'exe_directur'},
    {'name':'Direktur', 'value': 'directur'},
    {'name':'General Manager', 'value':'general_manager'},
    {'name':'Supervisor', 'value': 'supervisor'},
    {'name':'Leader', 'value':'leader'}
  ]

  const collectUserTypes = [
    {
      'id': 'verified', 'name': 'Terverifikasi'
    },
    {
      'id': 'process_verified', 'name': 'Pengajuan Terverifikasi'
    },
    {
      'id': 'no_verified', 'name': 'Belum Terverifikasi'
    }
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setIdCardNumber(dt.id_card_number);
        setUsername(dt.username);
        setEmail(dt.email);
        setFullName(dt.full_name);
        setGender(dt.gender);
        setBirthday(dt.birthday);
        setPhone1(dt.phone1);
        setPhone2(dt.phone2);
        setAddress(dt.address);
        setAddressCode(dt.address_code);
        setFullAddress(`${dt.district}, ${dt.city}, ${dt.province}`);
        setDomain(dt.domain);
        setMembership(dt.membership);
        setVerified(dt.verified);
        setVerifiedIdCard(dt.verified_id_card);
        setStaffId(dt.staff_id);
        setImageSrc(dt.avatar_url);
        setImageFile('');
        setIdCardSrc(dt.id_card_url);
        setIdCardFile('');
        setReffCode(dt.reff_code);
        setAffiliate(dt.affiliate);
        setInstagram(dt.instagram);
        setLinkMarketplace1(dt.link_marketplace1);
        setPromotion(dt.promotion);
        setFirstOrder(dt.first_order_value);
        setLastPackageId(dt.last_package_id);
        setLastFollowup(dt.last_followup);
        setLastFollowupDate(dt.last_followup_date);
        setLastFollowupNote(dt.last_followup_note);
        setPaymentBankName(dt.payment_bank_name);
        setPaymentBankKcp(dt.payment_bank_kcp);
        setPaymentBankNumber(dt.payment_bank_number);
        setRegisterMemberPrice(dt.register_member_price);
        setLevelType(dt.level_type)
      }else{
        setId('');
        setIdCardNumber('');
        setUsername('');
        setEmail('');
        setFullName('');
        setGender('');
        setBirthday('');
        setPhone1('');
        setPhone2('');
        setAddress('');
        setAddressCode('');
        setFullAddress();
        setDomain('');
        setMembership(false);
        setVerified(false);
        setVerifiedIdCard(false);
        setImageSrc('');
        setImageFile('');
        setIdCardSrc('');
        setIdCardFile('');
        setLastPackageId('');
        setStaffId('');
        setReffCode('');
        setAffiliate(false);
        setInstagram('');
        setLinkMarketplace1('');
        setPromotion(false);
        setFirstOrder(0);
        setLastFollowup('');
        setLastFollowupDate('');
        setLastFollowupNote('');
        setPaymentBankName('');
        setPaymentBankNumber('');
        setLevelType('');
        setPaymentBankKcp('');
      }
    }else{
      setId('');
      setIdCardNumber('');
      setUsername('');
      setEmail('');
      setFullName('');
      setGender('');
      setBirthday('');
      setPhone1('');
      setPhone2('');
      setAddress('');
      setAddressCode('');
      setFullAddress();
      setDomain('');
      setMembership(false);
      setVerified(false);
      setVerifiedIdCard(false);
      setImageSrc('');
      setImageFile('');
      setIdCardSrc('');
      setIdCardFile('');
      setLastPackageId('');
      setStaffId('');
      setReffCode('');
      setAffiliate(false);
      setInstagram('');
      setLinkMarketplace1('');
      setPromotion(false);
      setFirstOrder(0);
      setLastFollowup('');
      setLastFollowupDate('');
      setLastFollowupNote('');
      setPaymentBankName('');
      setPaymentBankNumber('');
      setRegisterMemberPrice(100000);
      setLevelType('');
      setPaymentBankKcp('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const handleIdCardSelect = (e) => {
    setIdCardSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setIdCardFile(files);
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('avatar', imageFile, imageFile.name);
    }
    if(idCardFile){
      fd.append('id_card', idCardFile, idCardFile.name);
    }
    fd.append('type', 'Member');
    fd.append('id_card_number', idCardNumber);
    fd.append('username', username);
    fd.append('email', email);
    fd.append('password', password);
    fd.append('full_name', fullName);
    fd.append('gender', gender);
    fd.append('birthday', birthday);
    fd.append('phone1', phone1);
    fd.append('phone2', phone2);
    fd.append('address', address);
    fd.append('address_code', addressCode);
    fd.append('domain', domain);
    fd.append('membership', membership);
    fd.append('verified', verified);
    fd.append('verified_id_card', verifiedIdCard);
    fd.append('last_package_id', lastPackageId);
    fd.append('staff_id', staffId);
    fd.append('reff_code', reffCode);
    fd.append('affiliate', affiliate);
    fd.append('instagram', instagram);
    fd.append('promotion', promotion);
    fd.append('first_order_value', firstOrder);
    fd.append('link_marketplace1', linkMarketplace1);
    fd.append('last_followup', lastFollowup);
    fd.append('last_followup_date', lastFollowupDate);
    fd.append('last_followup_note', lastFollowupNote);
    fd.append('payment_bank_name', paymentBankName);
    fd.append('payment_bank_number', paymentBankNumber);
    fd.append('payment_bank_kcp', paymentBankKcp);
    fd.append('register_member_price', registerMemberPrice);
    fd.append('level_type', levelType);
    fd.append('include', 'company');
    
    if(id){
      API.put(`users/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setData(oldData);
          }

          setId('');
          setIdCardNumber('');
          setUsername('');
          setEmail('');
          setFullName('');
          setGender('');
          setBirthday('');
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setDomain('');
          setMembership(false);
          setVerified(false);
          setImageSrc('');
          setImageFile('');
          setIdCardSrc('');
          setIdCardFile('');
          setLastPackageId('');
          setStaffId('');
          setReffCode('');
          setAffiliate(false);
          setInstagram('');
          setLinkMarketplace1('');
          setPromotion(false);
          setFirstOrder(0);
          setLastFollowup('');
          setLastFollowupDate('');
          setLastFollowupNote('');
          setPaymentBankName('');
          setPaymentBankNumber('');
          setRegisterMemberPrice(100000);
          setLevelType('');
          setPaymentBankKcp('');
          // loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('users', fd, config )
        .then(res => {
          setId('');
          setIdCardNumber('');
          setUsername('');
          setEmail('');
          setFullName('');
          setGender('');
          setBirthday('');
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setDomain('');
          setMembership(false);
          setVerified(false);
          setVerifiedIdCard(false);
          setImageSrc('');
          setImageFile('');
          setIdCardSrc('');
          setIdCardFile('');
          setLastPackageId('');
          setStaffId('');
          setReffCode('');
          setAffiliate(false);
          setInstagram('');
          setLinkMarketplace1('');
          setPromotion(false);
          setFirstOrder(0);
          setLastFollowup('');
          setLastFollowupDate('');
          setLastFollowupNote('');
          setPaymentBankName('');
          setPaymentBankNumber('');
          setRegisterMemberPrice(100000);
          setLevelType('');
          setPaymentBankKcp('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (keyword, level, staff_id, status, status_verified, uniq_code, level_type) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, level_type: level_type, by_uniq_code: uniq_code, by_level: level, by_staff_id: staff_id, by_status_account: status, by_status_verified: status_verified, by_user_type: 'Member', include: 'company, staff, last_package' }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })

    const config3 = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, pagination: false }};
    API.get(`packages`, config3)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPackages(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { key: search, level_type: searchLevelType, by_uniq_code: uniqCode, by_level: level, by_staff_id: byStaffId, by_status_account: searchStatus, by_status_verified: byStatusVerified, page: offset_page, key: search, by_user_type: 'Member', include: 'company, staff, last_package'} };
    
    API.get(`users`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, level, staffId, searchStatus, byStatusVerified, uniqCode, searchLevelType);
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.put(`users/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData(search, level, byStaffId, searchStatus, byStatusVerified, uniqCode, searchLevelType);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const updateSearchLevel = (s) => {
    setOffset(1);

    if(s === '' ){
      setLevel('');
    }else{
      setLevel(s);
    }
    loadData(search, s, byStaffId, searchStatus, byStatusVerified, uniqCode, searchLevelType);
  }

  const updateSearchLevelType = (s) => {
    setOffset(1);

    if(s === '' ){
      setSearchLevelType('');
    }else{
      setSearchLevelType(s);
    }
    loadData(search, level, byStaffId, searchStatus, byStatusVerified, uniqCode, s);
  }

  const updateUniqCode = (code) => {
    setOffset(1);
    setByUniqCode(code);
    loadData(search, level, byStaffId, searchStatus, byStatusVerified, code, searchLevelType);
  }

  const updateSearchStaffId = (staff_id) => {
    setOffset(1);
    setByStaffId(staff_id);
    loadData(search, level, staff_id, searchStatus, byStatusVerified, uniqCode, searchLevelType);
  }

  const updateSearchVerified = (status) => {
    setOffset(1);
    setByStatusVerified(status);
    loadData(search, level, byStaffId, searchStatus, status, uniqCode, searchLevelType);
  }

  const statusSearch = (status) => {
    setOffset(1);
    setSearchStatus(status);
    loadData(search, level, byStaffId, status, byStatusVerified, uniqCode, searchLevelType);
  }

  const exportData = () => {
    toggleExport();
    setLoadingExport(true);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { key: search, by_level: level, by_staff_id: byStaffId, by_status_account: searchStatus, pagination: false, key: search, by_user_type: 'Member', include: 'company, staff'} };
    API.get(`user_exports`, config)
      .then(res => {
        const data = res.data.report;
        setSpinner(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinner(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }
    setSearch('');
    loadData('');
  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola User</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-12'>
                  <FormGroup className="form-img-select">
                    <div class='avatar-user'>
                      <ImageUpload
                        handleImageSelect={handleImageSelect}
                        imageSrc={imageSrc}
                        setImageSrc={setImageSrc}
                        style={{
                          width: 120,
                          height: 120
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <Label className='label-form-group'>Tentukan Sponsor</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Masukan Kode Sponsor" disabled={id ? true : false}
                      value={reffCode} onChange={e => setReffCode(e.target.value)}
                    />
                  </InputGroup>
                </div>

                { currentUser?.type === 'Adminmaster' ? (
                  <>
                    <div className='col-6'>
                      <Label className='label-form-group'>Tentukan CS User</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={staffId} onChange={e => setStaffId(e.target.value)}>
                          <option value=''>Pilih CS User</option>
                          {
                            customerServices.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.full_name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>Tentukan Paket</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-users" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={lastPackageId} onChange={e => setLastPackageId(e.target.value)}>
                          <option value=''>Pilih Paket</option>
                          {
                            packages.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-12'>
                      <Label className='label-form-group'>Tentukan Paket</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-users" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={lastPackageId} onChange={e => setLastPackageId(e.target.value)}>
                          <option value=''>Pilih Paket</option>
                          {
                            packages.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                )}

                <div className='col-12'>
                  <Label className='label-form-group'>Tentukan Level</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input type="select" value={levelType} onChange={e => setLevelType(e.target.value)}>
                      <option value=''>Pilih Level</option>
                      {
                        levelTypes.map((c) => {
                          return (
                            <option key={c.value} value={c.value}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>

                <div className='col-12'>
                  <Label className='label-form-group'>No Identitas/KTP</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No Identitas"
                      value={idCardNumber} onChange={e => setIdCardNumber(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Alamat Email</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        @
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      value={email} onChange={e => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Kata Sandi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='password'
                      placeholder="Kata Sandi"
                      value={password} onChange={e => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Nama Lengkap</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-bookmark" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Lengkap"
                      value={fullName} onChange={e => setFullName(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak 1</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 1"
                      value={phone1} onChange={e => setPhone1(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak 2</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 2"
                      value={phone2} onChange={e => setPhone2(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Jenis Kelamin</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-info" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={gender} onChange={e => setGender(e.target.value)}>
                      <option value=''>Pilih Jenis Kelamin</option>
                      <option value='Laki-Laki'>Laki-Laki</option>
                      <option value='Perempuan'>Perempuan</option>
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Tanggal Lahir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker" value={birthday} onChange={e => setBirthday(e)} />
                  </FormGroup>
                </div>
                <div class="col-sm-12 autocomplete-field">
                  <div class="active" for="icon_prefix">
                    <small>Kecamatan Domisili</small>
                    { id !== '' ? (<a href='#' class='btn-right-label' onClick={ onChangeAddress } ><i class="ni ni-settings-gear-65"></i> Ganti Kecamatan</a>) : null }
                  </div>
                  { editAddress || id === '' ? (
                    <ReactSearchAutocomplete
                      items={districts}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      formatResult={formatResult}
                      />
                  ) : (
                    <div class='full-address-box'>
                      {fullAddress.includes('null') ? 'Alamat belum ditentukan' : fullAddress}
                    </div>
                  )}
                  <br/>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Alamat Lengkap</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-map-marker" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Alamat Lengkap"
                      value={address} onChange={e => setAddress(e.target.value)}
                    />
                  </InputGroup>
                </div>

                {/* <div className='col-sm-6'>
                  <Label className='label-form-group'>Pembelian Awal User</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        Rp
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type='number'
                      value={firstOrder} onChange={e => setFirstOrder(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-6'>
                  <Label className='label-form-group'>Instagram</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        @
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="@nama_instagram"
                      value={instagram} onChange={e => setInstagram(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Link Marketplace</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fas fa-globe" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="https://shopee.com/nama_toko"
                      value={linkMarketplace1} onChange={e => setLinkMarketplace1(e.target.value)}
                    />
                  </InputGroup>
                </div> */}

                <div className='col-sm-6 col-6'>
                  <Label className='label-form-group'>Pilih Bank</Label>
                  <InputGroup className="input-group-alternative">
                    <Input type='select' className='select' value={paymentBankName} onChange={e => setPaymentBankName(e.target.value)} >
                      <option value="">Pilih Bank</option>
                      <option value="BCA">BCA</option>
                      <option value="BNI">BNI</option>
                      <option value="MANDIRI">MANDIRI</option>
                      <option value="BRI">BRI</option>
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-sm-6 col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>KCP Kota</Label>
                    <Input
                      placeholder="KCP Kota"
                      value={paymentBankKcp} onChange={e => setPaymentBankKcp(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className='col-sm-6 col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Nomor Rekening</Label>
                    <Input
                      placeholder="No Rekening"
                      value={paymentBankNumber} onChange={e => setPaymentBankNumber(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className='col-sm-6 col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Harga Register Member</Label>
                    <Input
                      placeholder="Harga Pendaftaran Member"
                      value={registerMemberPrice} onChange={e => setRegisterMemberPrice(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={membership} onChange={e => setMembership(!membership)}/> Keanggotaan
                    </Label>
                  </FormGroup>
                </div>

                {/* <div className='col-4'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={affiliate} onChange={e => setAffiliate(!affiliate)}/> Referral
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={promotion} onChange={e => setPromotion(!promotion)}/> Dipromosikan
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-12'><hr/></div>
                <div className='col-6'>
                  <Label className='label-form-group'>Status FollowUp</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={lastFollowup} onChange={e => setLastFollowup(e.target.value)}>
                      <option value=''>Status FollowUp</option>
                      <option value='No Tidak Bisa di Hubungi'>No Tidak Bisa di Hubungi</option>
                      <option value='Susah Komunikasi'>Susah Komunikasi</option>
                      <option value='Kendala Berjualan'>Kendala Berjualan</option>
                      <option value='Penjualan Aktif'>Penjualan Aktif</option>
                      <option value='Penjualan Meningkat'>Penjualan Meningkat</option>
                      <option value='Sudah Tidak Jualan'>Sudah Tidak Jualan</option>
                      <option value='Tidak Minat Jadi User'>Tidak Minat Jadi User</option>
                      <option value='Lain-Lain'>Lain-Lain</option>
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>FollowUp Terakhir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker2" value={lastFollowupDate} onChange={e => setLastFollowupDate(e)} />
                  </FormGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>FollowUp Subyek</Label>
                  <Input type="select" value={lastFollowupNote} onChange={e => setLastFollowupNote(e.target.value)}>
                    <option value=''>Jenis Subyek</option>
                    <option value='Sampaikan Promo'>Sampaikan Promo</option>
                    <option value='Penawaran Naik Level'>Penawaran Naik Level</option>
                    <option value='Curhat Lewat Chat'>Curhat Lewat Chat</option>
                    <option value='Curhat Lewat Telpon'>Curhat Lewat Telpon</option>
                    <option value='Bantu Strategi Jualan'>Bantu Strategi Jualan</option>
                    <option value='Broadcast Pengumuman'>Broadcast Pengumuman</option>
                    <option value='Subyek Lain-Lain'>Subyek Lain-Lain</option>
                  </Input>
                </div> */}
              </div>

              <div className='row'>
                <div className='col-12'>
                  <div className='id-card'>
                    <FormGroup className="form-img-select">
                      <div class='avatar-user'>
                        <ImageUpload
                          handleImageSelect={handleIdCardSelect}
                          imageSrc={idCardSrc}
                          setImageSrc={setIdCardSrc}
                          style={{
                            width: 300,
                            height: 190
                          }}
                        />
                      </div>
                    </FormGroup>
                    <p align='center'>Upload Tanda Pengenal KTP/SIM/Passport</p>
                  </div>
                </div>
                <div className='col-12'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verifiedIdCard} onChange={e => setVerifiedIdCard(!verifiedIdCard)}/> Identitas Diverifikasi
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2 m-bottom-20'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  { (currentUser.type === 'Staff' && currentUser.role_access === 'cs') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                    <>
                      <div className='col-sm-4 m-bottom-20'>
                        <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                      </div>
                      <div className='col-sm-3 m-bottom-20'>
                        <Input type="select" value={byStatusVerified} onChange={e => updateSearchVerified(e.target.value)}>
                          <option value=''>Pilih Type</option>
                          {
                            collectUserTypes.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </div>
                      <div className='col-sm-3 m-bottom-20'>
                        <Input type="select" value={byStaffId} onChange={e => updateSearchStaffId(e.target.value)}>
                          <option value=''>Pilih CS User</option>
                          <option value='no_cs'>Tidak Mempunyai Cs</option>
                          {
                            customerServices.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.full_name}</option>
                              )
                            })
                          }
                        </Input>
                      </div>
                      <div className='col-sm-3 m-bottom-20'>
                        <input type='text' placeholder='Package' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                      </div>
                      <div className='col-sm-3 m-bottom-20'>
                        <Input type="select" value={searchLevelType} onChange={e => updateSearchLevelType(e.target.value)}>
                          <option value=''>Pilih</option>
                          {
                            levelTypes.map((c) => {
                              return (
                                <option key={c.value} value={c.value}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </div>
                      <div className='col-sm-3 m-bottom-20'>
                        <input type='text' placeholder='Harga Pendaftaran' className='form-control' value={uniqCode} onChange={e => updateUniqCode(e.target.value)}/>
                      </div>
                      <div className='col-sm-1 m-bottom-20'>
                        <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany?.company_color3)}}><i class="fas fa-plus"></i></Button>
                      </div>
                      <div className='col-sm-1 m-bottom-20'>
                        <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-sm-10 m-bottom-20'>
                        <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                      </div>
                    </>
                  )}
                </div>
 
                {/* <div className='row' style={{marginTop: 20}}>
                  <div className='col-sm-12'>
                    <div className='tab-nav back-white'>
                      <ul className='margin-none'>
                        {navCollection.map((nav, index) => {
                          return (
                            <li key={index+=1} className={nav.value === searchStatus ? 'active' : null } onClick={() => { statusSearch(nav.value)} }>
                              {nav.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div> */}

              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    { (currentUser.type === 'Staff' && currentUser.role_access === 'cs') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                      <>
                        <th width='100px' scope="col text-center">Action</th>
                        <th scope="col">Status</th>
                      </>
                    ) : null}
                    <th scope="col">Nama User</th>
                    <th scope="col">Status</th>
                    <th scope="col">Virtual</th>
                    <th scope="col">Kontak</th>
                    <th scope="col">Email</th>
                    <th scope="col">Terdaftar</th>
                    <th scope="col">Alamat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="9" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              { (currentUser.type === 'Staff' && currentUser.role_access === 'cs') || (currentUser.type === 'Staff' && currentUser.role_access === 'admin') || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' ? (
                                <>  
                                  <td width='100px' className="text-right">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        className="btn-icon-only text-light"
                                        href="#pablo"
                                        role="button"
                                        size="sm"
                                        color=""
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem href={`/admin/member/${u.id}`}>
                                          Lihat
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => toggle(u.id)}>
                                          Ubah
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                  <td>
                                  <div style={styles.status_verified(u.verified)}>
                                    {u.verified ? (
                                      <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                    ) : (
                                      <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                    )}
                                  </div>
                                </td>
                                </>
                              ) : null }
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                    <img alt="..." src={u.avatar_url} />
                                  </a>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {u.full_name}
                                      <br/>
                                      {
                                        u.verified_id_card ? (
                                          <>
                                            <i class="fa fa-check-circle" style={{marginRight:5, color: 'green'}}></i> <small>Verified</small>
                                          </>
                                        ) : null
                                      }
                                      {
                                        u.verified_id_card === false && (u.payment_bank_name && u.payment_bank_name != 'null') && (u.payment_bank_number && u.payment_bank_number != 'null') && u.id_card_number && (u.id_card_url && !u.id_card_url.includes('no-img')) ? (
                                          <>
                                            <i class="fa fa-exclamation-circle" style={{marginRight:5, color: 'blue'}}></i> <small>Pengajuan Verifikasi</small>
                                          </>
                                        ) : null
                                      }
                                      <br/>
                                      <small>Reff: {u.code}</small>
                                      <br/>
                                      <label style={{marginRight: 2}} className='label label-default'>{u.last_package ? u.last_package.title : 'Customer'}</label>
                                      {
                                        u.level_type ? (
                                          <label className='label label-default'>{u.level_type_txt}</label>
                                        ) : null
                                      }
                                      <br/>
                                      { u.staff_id ? (<small>CS User: {u.staff_name}</small>) : null}
                                      <br/>
                                      <small>Login Terakhir: {u.last_sign_in_at ? u.last_sign_in_at : '-'}</small>
                                      <br/>
                                      <small>Register Member: {u.register_member_price ? currencyFormat(u.register_member_price) : '-'}</small>
                                    </span>
                                  </Media>
                                </Media>  
                              </th>
                              <td>
                                <div>
                                  Akun:  <label style={styles.status(u.verified)}>{u.verified ? 'Aktif' : 'Blum Aktif'}</label>
                                </div>
                                <div>
                                  Keanggotaan: <label style={styles.status(u.membership)}>{u.membership ? 'Aktif' : 'Blum Aktif'}</label>
                                </div>
                                <div>
                                  {u.reff_code ? 'Sponsor Reff: '+u.reff_code : ''}
                                </div>
                              </td>
                              <td>
                                Saldo: {currencyFormat(u.virtual_money)}
                                <br/>
                                Poin: {numberFormat(u.virtual_point)}
                              </td>
                              <td>
                                <a href={`https://wa.me/`+u.phone1} target="_blank" className='btn-wa' style={{marginBottom: 5, display: 'inline-block'}}>{u.phone1}</a>
                                <br/>
                                {
                                  u.phone2 > 5 ? (<a href={`https://wa.me/`+u.phone2} target="_blank" className='btn-wa'>{u.phone2}</a>) : null
                                }
                              </td>
                              <td>
                                {u.email}
                              </td>
                              <td>
                                <div>Join {u.created_at}</div>
                                {
                                  u.start_active_at ? (
                                    <div>Aktif {u.start_active_at}</div>
                                  ) : null
                                }
                                { u.first_order_value > 0 ? (
                                  <div>Pembelian Awal {numberFormat(u.first_order_value)}</div>
                                ) : null}
                                <div>{u.last_order_date ? `Pesanan Terakhir ${u.last_order_date}` : ''} </div>
                                <div>{ u.last_followup_date ? `Follow Up Terakhir ${u.last_followup_date}` : '' }</div>
                                <div>{ u.last_followup_txt ? (<label className='label label-default'>{u.last_followup_txt}</label>) : null }</div>
                                <div>{ u.last_followup_note ? (<label className='label label-default'>{u.last_followup_note}</label>) : null }</div>
                              </td>
                              <td>
                                {u.province} <br/>
                                {u.city} <br/>
                                {u.district}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
                </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MemberIndex;

const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : 'red',
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_verified: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}


