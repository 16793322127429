import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  CardBody
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  let nu = num ? num : 0
  return 'Rp' + parseFloat(nu).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  let nu = num ? num : 0
  return '' + parseFloat(nu).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ShopIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const member = ReactSession.get("current_member");

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const current_order_id = ReactSession.get("current_order_id");
  // const [member, setMember] = useState({});
  // const [memberId, setMemberId] = useState('');
  const [originId, setOriginId] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [shippingPrice, setShippingPrice] = useState(0);
  const [subsidi, setSubsidi] = useState(0);
  const [company, setCompany] = useState({});
  const [paymentNumbers, setPaymentNumbers] = useState({});
  const [expeditionsOld, setExpeditionsOld] = useState([]);
  const [expeditions, setExpeditions] = useState([]);
  const [expedition, setExpedition] = useState({});
  const [expeditionServices, setExpeditionServices] = useState([]);
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [feeCOD, setFeeCOD] = useState(0);

  const [orderItem, setOrderItem] = useState({});
  const [showBoxCart, setShowBoxCart] = useState(false);
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [variant1, setVariant1] = useState('');
  const [variant2, setVariant2] = useState('');
  const [variant1Obj, setVariant1Obj] = useState({});
  const [variant2Obj, setVariant2Obj] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [productPriceSell, setProductPriceSell] = useState(0);
  const [variants2, setVariants2] = useState([]);
  const [haveVariant2, setHaveVariant2] = useState(false);
  const [storePrice, setStorePrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [spinnerModal, setSpinnerModal] = useState(false);

  const [editUserOrder, setEditUserOrder] = useState(false);
  const [editUserSender, setEditUserSender] = useState(false);
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [queryMember, setQueryMember] = useState('');
  const [itemMembers, setItemMembers] = useState([]);

  const [mitra, setMitra] = useState(false);
  const [dropship, setDropship] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState('Transfer');
  
  const [storeName, setStoreName] = useState('');
  const [storeNumber, setStoreNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const [expeditionId, setExpeditionId] = useState('');
  const [expeditionName, setExpeditionName] = useState('');
  const [expeditionServiceName, setExpeditionServiceName] = useState('');
  const [expeditionService, setExpeditionService] = useState('');
  const [expeditionWeight, setExpeditionWeight] = useState(0);
  const [expeditionNumber, setExpeditionNumber] = useState();

  const [spinnerScreen, setSpinnerScreen] = useState(true);
  const [spinnerCheckout, setSpinnerCheckout] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setVariant1('');
    setVariant1Obj({})

    setVariant2('');
    setVariant2Obj({})
    setHaveVariant2(false)
    setVariants2([]);

    setShowBoxCart(false);
    setErrorMessages('');
    let dt = data.filter(o => o.id === id)[0]
    
    if(dt){
      setProduct(dt);
      setSellPrice(parseInt(dt.sell_price)-(parseInt(dt.sell_price)*parseInt(dt.discount_customer)/100))
      if(dt.product_variants && dt.product_variants.filter(o => o.parent_id === null).length > 0){
        let varian_sku = dt.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
        let opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: varian_sku.sku} }
        setVariant1(varian_sku.sku);
        setVariant1Obj(varian_sku);
        if(dt.product_type == 'price_on_variant' && dt.product_prices.length > 0){
          let pPrice = dt.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === varian_sku.id )[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
            setProductPriceSell(varian_sku.sell_price);
            setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(dt.discount)/100))
          }else{
            setProductPrice(varian_sku.sell_price);
            setProductPriceSell(varian_sku.sell_price);
            setStorePrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(dt.discount)/100))
          }
          setSellPrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(dt.discount_customer)/100))
        }else{
          let pPrice = dt.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null )[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
            setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(dt.discount)/100))
          }else{
            setProductPrice(dt.sell_price);
            setStorePrice(parseInt(dt.sell_price)-(parseInt(dt.sell_price)*parseInt(dt.discount)/100))
          }
          setProductPriceSell(dt.sell_price);
          setStorePrice(parseInt(dt.sell_price)-(parseInt(dt.sell_price)*parseInt(dt.discount)/100))
        }
        
        API.get(`product_variants`, opts)
          .then(res => {
            const data = res.data.data;
            if(data && data.length > 0){
              setVariant2(data[0].sku);
              setVariant2Obj(data[0])
              setHaveVariant2(true)
              setVariants2(data);
            }else{
              setVariant2('');
              setVariant2Obj({})
              setHaveVariant2(false)
              setVariants2([]);
            }
          })
      }else{
        let pPrice = dt.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null)[0]
        if(pPrice !== undefined){
          setProductPrice(pPrice.sell_price);
          setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(dt.discount)/100))
        }else{
          setProductPrice(dt.sell_price);
          setStorePrice(parseInt(dt.sell_price)-(parseInt(dt.sell_price)*parseInt(dt.discount)/100))
        }
        setProductPriceSell(dt.sell_price);
      }
    }else{
      setProduct({});
    }
      
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addCart = (id) => {
    // setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      product_id: id, store_price: storePrice, drop_price: sellPrice, variant_sku_1: variant1, variant_sku_2: variant2, quantity: quantity, order_id: current_order_id, member_id: (member ? member.id : null)
    };

    API.post('order_items', paramsData, config)
      .then(res => {
        // setSpinner(false);
        // alert('Produk ini berhasil di masukan ke keranjang anda')
        const data = res.data.data;
        ReactSession.set("current_order_id", data.order_id);
        loadDataShop(data.order_id, expeditionId);
        setModal(!modal);
      }).catch(error => {
        // setSpinner(false);
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const IncrementItemModal = () => {
    let qty = (quantity+1);
    setQuantity(qty)
  }

  const DecreaseItemModal = () => {
    let qty = quantity-1
    if(qty <= 1){
      setQuantity(1)
    }else{
      setQuantity((quantity-1))
    }
  }

  const IncrementItem = (quantity, oi) => {
    let qty = (quantity+1);
    updateQuantity(qty, oi)
  }

  const DecreaseItem = (quantity, oi) => {
    let qty = quantity-1
    if(qty <= 1){
      updateQuantity(1, oi)
    }else{
      updateQuantity((quantity-1), oi)
    }
  }

  const updateQuantity = (quantity, oi) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      store_price: storePrice, quantity: quantity, call_order: true, include: 'order_items, product', member_id: (member ? member.id : null)
    };

    API.put(`order_items/${oi}`, paramsData, config)
      .then(res => {
        const data = res.data.data;
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
      }).catch(error => {
        if(error === undefined){
          alert('Gagal, Telah terjadi kesalahan server');
        }else{
          alert(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const deleteItem = (oi) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    
    API.delete(`order_items/${oi}?call_order=true&include=order_items,product`, config)
      .then(res => {
        const data = res.data.data;
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
      }).catch(error => {
        if(error === undefined){
          alert('Gagal, Telah terjadi kesalahan server');
        }else{
          alert(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const loadDataShop = (order_id, expedition_id) => {
    let _expedition_id = expedition_id ? expedition_id : expeditionId
    let _order_id = order_id ? order_id : current_order_id
    let _customerName = customerName
    let _customerPhone = customerPhone
    let _customerEmail = customerEmail
    let _address = address
    let _addressCode = destinationId
    
    if(member && dropship === false && mitra === true){
      _customerName = _customerName ? _customerName : member.full_name
      _customerPhone = _customerPhone ? _customerPhone : member.phone1
      _customerEmail = _customerEmail ? _customerEmail : member.email
      _address = _address ? _address : member.address
      _addressCode = _addressCode ? _addressCode : member.address_code
    }

    setDestinationId(_addressCode);
    setCustomerName(_customerName);
    setCustomerEmail(_customerEmail);
    setCustomerPhone(_customerPhone);
    setCustomerPaymentMethod('Transfer');
    setAddress(_address);
    setAddressCode(_addressCode);
    setFullAddress(fullAddress);
    setDistricts(districts);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'order_items, product'} };
    API.get(`orders/${_order_id}`, config)
      .then(res => {
        const data = res.data.data;
        setOrder(data);
        setOrderItems(data.order_items);
        setExpeditionWeight(data.expedition_weight);

        setOriginId(data.store_address_code);
        setGetServiceExpedition(_expedition_id, data.store_address_code, _addressCode, data.expedition_weight, data);
      })
    
  }

  const onHideCart = () => {
    setModal(!modal);
    setOrderItem({});
    setProduct({});
  }

  const modalEditCart = (id) => {
    setShowBoxCart(true);

    let order_item = orderItems.filter(o => o.id === id )[0]
    setOrderItem(order_item);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'user, product_variants, product_variant_prices, product_prices'} };

    API.get(`products/${order_item.product_id}`, config)
      .then(res => {
        setSpinnerModal(false);
        const data = res.data.data;
        setProduct(data);
        setQuantity(order_item.quantity)
        setProductPrice(data.sell_price);
        setStorePrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount)/100))
        setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount_customer)/100))
        
        if(data.product_variants && data.product_variants.filter(o => o.parent_id === null).length > 0){
          let varian_sku = data.product_variants.filter(o => o.parent_id === null && o.sku === order_item.variant_sku_1)[0]
          let opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: varian_sku.sku} }
          setVariant1(varian_sku.sku);
          setVariant1Obj(varian_sku)

          if(data.product_type == 'price_on_variant' && data.product_prices.length > 0){
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === varian_sku.id )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
              setProductPriceSell(varian_sku.sell_price);
              setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(data.discount)/100))
            }else{
              setProductPrice(varian_sku.sell_price);
              setProductPriceSell(varian_sku.sell_price);
              setStorePrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(data.discount)/100))
            }
            setSellPrice(parseInt(varian_sku.sell_price)-(parseInt(varian_sku.sell_price)*parseInt(data.discount_customer)/100))
          }else{
            let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
              setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(data.discount)/100))
            }else{
              setProductPrice(data.sell_price);
              setStorePrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount)/100))
            }
            setProductPriceSell(product.sell_price);
          }
          
          API.get(`product_variants`, opts)
            .then(res => {
              const data = res.data.data;
              if(data && data.length > 0){
                setVariant2(order_item.variant_sku_2);
                setVariant2Obj(data.filter(o => o.sku === order_item.variant_sku_2)[0])
                setHaveVariant2(true)
                setVariants2(data);
              }else{
                setVariant2('');
                setVariant2Obj({})
                setHaveVariant2(false)
                setVariants2([]);
              }
            })
        }else{
          let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null)[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
            setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(data.discount)/100))
          }else{
            setProductPrice(data.sell_price);
            setStorePrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(data.discount)/100))
          }
          setProductPriceSell(data.sell_price);
        }

        setModal(true);
      })
  }

  const loadVariant = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku, include: 'product_variant_prices'} };
    setVariant1(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant1Obj(data);
        let pPrice = product.sell_price;
        if(product.product_prices.length > 0){
          if(product.product_type == 'price_on_variant'){
            pPrice = product.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === data.id )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
              setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(product.discount)/100))
              setProductPriceSell(data.sell_price);
            }else{
              setProductPrice(data.sell_price);
              setStorePrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(product.discount)/100))
              setProductPriceSell(data.sell_price);
            }
            setSellPrice(parseInt(data.sell_price)-(parseInt(data.sell_price)*parseInt(product.discount_customer)/100))
          }else{
            pPrice = product.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
              setStorePrice(parseInt(pPrice.sell_price)-(parseInt(pPrice.sell_price)*parseInt(product.discount)/100))
            }else{
              setProductPrice(product.sell_price);
              setStorePrice(parseInt(product.sell_price)-(parseInt(product.sell_price)*parseInt(product.discount)/100))
            }
            setProductPriceSell(product.sell_price);
          }
        }else{
          setProductPrice(product.sell_price);
          setStorePrice(parseInt(product.sell_price)-(parseInt(product.sell_price)*parseInt(product.discount)/100))
          setProductPriceSell(product.sell_price);
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: sku} };
    API.get(`product_variants`, config2)
      .then(res => {
        const data = res.data.data;
        if(data && data.length > 0){
          setVariant2(data[0].sku);
          setVariant2Obj(data[0])
          setHaveVariant2(true)
          setVariants2(data);
        }else{
          setVariant2('');
          setVariant2Obj({})
          setHaveVariant2(false)
          setVariants2([]);
        }
      })
  }

  const setLoadVariant2 = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant2(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant2Obj(data)
      })
  }
  
  const editCart = (id) => {
    // setSpinner(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      member_id: (member ? member.id : null), store_price: storePrice, drop_price: sellPrice, variant_sku_1: variant1, variant_sku_2: variant2, quantity: quantity, call_order: true, include: 'order_items,product'
    };
    
    API.put(`order_items/${id}`, paramsData, config)
      .then(res => {
        // setSpinner(false);
        // alert('Keranjang Belanja Berhasil Dirubah!')
        const data = res.data.data;
        setModal(!modal);
        setOrderItems(data.order_items);
        setOrder(data);
        setExpeditionWeight(data.expedition_weight);
        setGetServiceExpedition(expeditionId, originId, destinationId, data.expedition_weight, data);
      }).catch(error => {
        // setSpinner(false);
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
    setFullAddress(item.name)
    setDestinationId(item.id)
    // getServiceExpedition(expeditionName, originId, item.id, order.expedition_weight);
    setGetServiceExpedition(expeditionId, originId, item.id, order.expedition_weight);
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnSearchMember = (string, results) => {
    console.log(string, results)
    findMember(string)
  }

  const handleOnHoverMember = (result) => {
    console.log(result)
  }

  const handleOnSelectMember = (item) => {
    let _member = itemMembers.filter((m) => m.id === item.id)[0]
    ReactSession.set("current_member", _member);
    // setMember(_member);
    // setMemberId(item.id);

    setDestinationId(_member.address_code);
    setCustomerName(_member.full_name);
    setCustomerEmail(_member.email);
    setCustomerPhone(_member.phone1);
    setCustomerPaymentMethod('Transfer');
    
    setAddress(_member.address);
    setAddressCode(_member.address_code);
    setFullAddress(`${_member.district}, ${_member.city}, ${_member.province}`)
    setDistricts([{id: _member.address_code, name: _member.district+','+_member.city+','+_member.province }])

    setGetServiceExpedition(expeditionId, originId, _member.address_code, order.expedition_weight);
  }

  const handleOnFocusMember = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const findMember = (query) => {
    setQueryMember(query);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: query, by_user_type: 'Member', include: '' }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        setItemMembers(data);
      })
  }
  
  const onChangeUserOrder = () => {
    editUserOrder === true ? setEditUserOrder(false) : setEditUserOrder(true)
  }

  const onChangeUserSender = () => {
    editUserSender === true ? setEditUserSender(false) : setEditUserSender(true)
  }

  const onSelectDropshiper = (value) => {
    setDropship(!dropship)
    if(!dropship === true){
      setEditUserSender(true)
      setStoreName(member.full_name);
      setStoreNumber(member.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, true);
    }else{
      setEditUserSender(false)
      setStoreName(company.full_name);
      setStoreNumber(company.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, false);
    }
  }

  const onSelectMitra = (value) => {
    setMitra(!mitra)

    if(!mitra === true){
      setEditUserSender(true)
      setStoreName(currentUser.full_name);
      setStoreNumber(currentUser.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, true);
    }else{
      setEditUserSender(false)
      setStoreName(company.full_name);
      setStoreNumber(company.phone1);
      setCustomerPaymentMethodLoad(customerPaymentMethod, false);
      ReactSession.remove("current_member");
      setCustomerName('');
      setCustomerEmail('');
      setCustomerPhone('');
      setAddress('');
      setFullAddress('');
      setDropship(false);
    }
  }

  const setGetServiceExpedition = (ex_id, origin, destination, weight, order) => {
    let _expedition = expeditionsOld.filter((x) => x.id === parseInt(ex_id))[0];
    setExpeditionId(parseInt(ex_id));
    setExpedition(_expedition);
    
    if(_expedition){
      setCustomerPaymentMethodLoad(customerPaymentMethod, dropship, order, _expedition, destination, weight);
    }
  }

  const setCustomerPaymentMethodLoad = (pMethod, drops, _order, ex, _destination, _weight) => {
    setCustomerPaymentMethod(pMethod);

    let _feeCOD = 0
    let statusDropship = drops !== undefined ? drops : dropship
    let dOrder = _order ? _order : order
    let exp = ex ? ex : expedition
    let h_expeditions = expeditionsOld
    let dDestination = _destination ? _destination : destinationId
    let dWeight = _weight ? _weight : expeditionWeight

    if(pMethod.includes('COD')){
      h_expeditions = expeditionsOld.filter((ex) => ex.cod === true);
      setExpeditions(h_expeditions);
      if(exp.cod !== true){
        exp = h_expeditions[0]
      }
    }else{
      setExpeditions(h_expeditions);
      if(exp === undefined){
        exp = h_expeditions[0]
      }
    }
    
    if(exp){
      setExpeditionId(parseInt(exp.id));
      setExpedition(exp);
      setExpeditionName(exp.name);

      const config = { 
        headers: { Authorization: `Bearer ${token}` }, 
        params: { name: exp.name.toLowerCase(), origin_id: dOrder.store_address_code, district_id: dDestination, weight: dWeight} 
      };
      
      API.get(`expedition_shipping`, config)
        .then(res => {
          const data = res.data.report;
          setExpeditionServices(data);
          if(data && data.length > 0){
            let expd = data[0]
            setExpeditionService(expd.service+'-'+expd.price.toString()); 
            setExpeditionServiceName(expd.service); 
            setShippingPrice(parseInt(expd.price));

            if(exp && exp.cod === true && pMethod.includes('COD')){
              if(exp.cod_type_percentage === 'total_order'){
                _feeCOD = parseInt(dOrder.sell_price) * (exp.cod_presentage/100);
              }else{
                _feeCOD = (parseInt(dOrder.sell_price)+parseInt(expd.price)) * (exp.cod_presentage/100);
              }
            }else{
              _feeCOD = 0;
            }
            setFeeCOD(_feeCOD);

            if(dOrder && dOrder.subsidi > 0 && parseInt(dOrder.subsidi) >= (parseInt(expd.price)+_feeCOD) ){
              setSubsidi((parseInt(expd.price)+_feeCOD));
            }
          }
        })

    }else{
      setFeeCOD(0);
    }
  }

  const getOngkir = (ex, origin, destination, weight, order) => {
    setExpeditionName(ex)
    
    let or = origin ? origin : originId
    let des = destination ? destination : addressCode
    let wei = weight ? weight : order.expedition_weight
    const config = { 
      headers: { Authorization: `Bearer ${token}` }, 
      params: { 
        name: ex,
        origin_id: or,
        district_id: des,
        weight: wei
      } 
    };
    
    API.get(`expedition_shipping`, config)
      .then(res => {
        const data = res.data.report;
        setExpeditionServices(data)
        
        if(data && data.length > 0){
          let expd = data[0]
          setExpeditionService(expd.service+'-'+expd.price.toString()); 
          setExpeditionServiceName(expd.service); 
          setShippingPrice(parseInt(expd.price));

          if(order && order.subsidi > 0 && parseInt(order.subsidi) >= parseInt(expd.price) ){
            setSubsidi(parseInt(expd.price));
          }
        }
      })
  }

  const setServiceExpedition = (select) => {
    let ser = select.split('-')[0]
    let ship = select.split('-')[1]
    let _feeCOD = 0

    setExpeditionService(select); 
    setExpeditionServiceName(ser); 
    setShippingPrice(parseInt(ship));

    if(expedition && expedition.cod === true && dropship && customerPaymentMethod.includes('COD')){
      if(expedition.cod_type_percentage === 'total_order'){
        _feeCOD = parseInt(order.sell_price) * (expedition.cod_presentage/100);
      }else{
        _feeCOD = (parseInt(order.sell_price)+parseInt(ship)) * (expedition.cod_presentage/100);
      }
    }else{
      _feeCOD = 0;
    }
    setFeeCOD(_feeCOD);

    if(order && order.subsidi > 0 && parseInt(order.subsidi) >= (parseInt(ship)+_feeCOD) ){
      setSubsidi((parseInt(ship)+_feeCOD));
    }
  }

  const checkoutAct = () => {
    setSpinnerCheckout(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` }
    };

    let _customerName = customerName
    let _customerPhone = customerPhone
    let _customerEmail = customerEmail
    let _address = address
    let _addressCode = addressCode
    
    if(member && dropship === false && mitra === true){
      _customerName = _customerName ? _customerName : member.full_name
      _customerPhone = _customerPhone ? _customerPhone : member.phone1
      _customerEmail = _customerEmail ? _customerEmail : member.email
      _address = _address ? _address : member.address
      _addressCode = _addressCode ? _addressCode : member.address_code
    }

    const paramsData = { 
      id: order.id,
      mitra: mitra,
      member_id: (member ? member.id : null),
      dropship: dropship,
      customer_name: _customerName,
      customer_email: _customerEmail,
      customer_phone: _customerPhone,
      customer_address: _address,
      customer_address_code: _addressCode,
      customer_payment_method: customerPaymentMethod,
      store_name: storeName,
      store_number: storeNumber,
      expedition_id: expeditionId,
      expedition_name: expeditionName,
      expedition_service: expeditionServiceName,
      expedition_number: expeditionNumber
    };

    API.post(`orders`, paramsData, config)
      .then(res => {
        const data = res.data.data;
        alert('Pesanan Baru Berhasil')
        ReactSession.remove("current_order_id");
        ReactSession.remove("current_order_count");
        ReactSession.remove("current_member");
        setSpinnerCheckout(false);
        history.push(`order/${data.id}`);
      }).catch(error => {
        if(error === undefined){
          alert('Gagal Telah terjadi kesalahan server')
        }else{
          alert(`Gagal ` + error.response.data.meta.status.message);
        }
        setSpinnerCheckout(false);
      })
  }

  const loadData = (keyword) => {  
    if(member && customerName === undefined){
      ReactSession.remove("current_member");
    }
      
    let com = ''
    let data = ''
    const config_company = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'payment_numbers, expeditions'} };

    API.get(`user_company`, config_company)
      .then(res => {
        com = res.data.data;
        setCompany(com);
        setStoreName(com.full_name);
        setStoreNumber(com.phone1);
        setPaymentNumbers(com.payment_numbers);
        setExpeditions(com.expeditions);
        setExpeditionsOld(com.expeditions);

        if(com && com.expeditions && com.expeditions.length > 0){
          setExpedition(com.expeditions[0]);
          setExpeditionId(com.expeditions[0].id);
          loadDataShop('', com.expeditions[0].id);
        }

        const _config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'order_items, product'} };
        API.get(`orders/${current_order_id}`, _config)
          .then(res => {
            data = res.data.data;
            setOrder(data);
            setOrderItems(data.order_items);
            setExpeditionWeight(data.expedition_weight);
            setOriginId(data.store_address_code);

            if(data && com && com.expeditions && com.expeditions.length > 0){
              getOngkir(com.expeditions[0].name.toLowerCase(), data.store_address_code, (member ? member.address_code : addressCode), data.expedition_weight, data);
            }
          })
      })

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_status: true, include: 'user, product_prices, product_variants' }};
    API.get(`products`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', by_status: true, include: 'user, product_prices, product_variants'} };
    
    API.get(`products`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    setSearch('');
    loadData('');

  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Detail Produk {product.code}</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <FormGroup className="mb-3">
            <div class="row widget-box-chart">
              <div class="col-md-12 mb-20">
                <div class="align-items-center media">
                  <a class="avatar rounded-circle mr-3" style={{width:80}}>
                    <img src={product.image_url}/>
                  </a>
                  <div class="media">
                    <span class="mb-0 text-sm">
                      <h4>{product.title}</h4>
                      { variant1Obj.variant_name ? (
                        <div class='card-variant'>Pilihan Varian: {variant1Obj.variant_name} {variant2Obj.variant_name}</div>
                      ) : null}
                      Harga User : 
                      <b class='card-variant'>
                        {product.discount > 0 ? (<span className='discount-price'>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(product.discount)/100))}
                      </b>
                      <br/>
                      Harga Konsumen : 
                      <b class='card-variant'>
                        {product.discount_customer > 0 ? (<span className='discount-price'>{currencyFormat(productPriceSell)}</span>) : null} {currencyFormat(parseInt(productPriceSell)-(parseInt(productPriceSell)*parseInt(product.discount_customer)/100))}
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              {
                product.product_variants && product.product_variants.length > 0 ? (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Pilih Varian</div>
                    </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <select class="form-control" value={variant1} onChange={e => (loadVariant(e.target.value))} >
                          {
                            product.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id).map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </>
                ) : null
              }

              {
                haveVariant2 && variants2 ? (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Pilih Varian 2</div>
                      </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <select class="form-control" value={variant2} onChange={e => (setLoadVariant2(e.target.value))} >
                          {
                            variants2.map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </>
                ) : null
              }

              {
                member && member.group_user_id !== null ? (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Harga User</div>
                    </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <Input type='number' placeholder="Harga Jual User" value={storePrice} onChange={e => setStorePrice(e.target.value)} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Harga Konsumen</div>
                    </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <Input type='number' placeholder="Harga Jual User" value={sellPrice} onChange={e => setSellPrice(e.target.value)} />
                      </div>
                    </div>
                  </> 
                )
              }

              <div class="col-sm-6">
                <div class='title-card-label m-top-10'>Jumlah</div>
              </div>
              <div class="col-sm-6">
                <div className='form-group'>
                  <div class='input-group'>
                    <button class='btn-increament btn btn-default' style={{marginTop: 0}} onClick={IncrementItemModal}>+</button>
                    <input placeholder="Jumlah" type="number" min='1' class="validate input-border form-control text-center input-increament" value={quantity} onChange={e => setQuantity(e.target.value)} />
                    <button class='btn-increament btn btn-default' style={{marginTop: 0}} onClick={DecreaseItemModal}>-</button>
                  </div>
                </div>
              </div>

              {/* <div class="col-sm-12">
                {product.description}
              </div> */}

              <div class="col-sm-12">
                { errorMessages ? (
                  <div class="error-card" style={{color: 'red'}}>
                    <span>{errorMessages}</span>
                  </div>
                ) : null}
                { spinner ? (
                  <div class="col-sm-12" >
                    <center><Dots color='#444' /></center>
                  </div>
                ) : null }
              </div>

            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className='row' style={{width:'100%'}}>
            <div className='col-sm-6'>
              <Button className='full-width' color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
            </div>
            <div className='col-sm-6'>
              {  showBoxCart && orderItem && product ? (
              <Button className='full-width' color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => editCart(orderItem.id)}>Ubah Pemesanan</Button>  
              ) : (
              <Button className='full-width' color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => addCart(product.id)}>Masukan Keranjang</Button>  
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>


      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col-lg-4 col-sm-12">
            <Card className="shadow" style={{marginBottom: 10}}>
              <CardHeader className="border-0">
                <h3 align='center'>Keranjang Belanja</h3>
              </CardHeader>
              <div class='col-sm-12'>
                <div className="">
                  <div className="card horizontal card-cart">
                    <b>Jenis Pemesanan</b>
                    <label className='btn-right-label2'>
                      <input type="checkbox" defaultChecked={mitra} onChange={() => onSelectMitra(!mitra)} />
                      <span> Pemesanan User</span>
                    </label>
                  </div>
                  
                  { mitra ? (
                    <div className="card horizontal card-cart">
                      <div className="row m-bottom0">
                        <div className="input-field col-sm-12 autocomplete-field">
                          <label className="active" for="icon_prefix">
                            Cari & Pilih User Pemesan
                          </label>
                          <ReactSearchAutocomplete
                            items={itemMembers}
                            onSearch={handleOnSearchMember}
                            onHover={handleOnHoverMember}
                            onSelect={handleOnSelectMember}
                            formatResult={formatResult}
                            />
                        </div>
                      </div>
                    </div>
                  ) : null }

                  <div className="card horizontal card-cart">
                    <b>Alamat Pengiriman</b>
                    <span style={{cursor: 'pointer'}} className='btn-right-label2' onClick={ onChangeUserOrder } ><i className="fa fa-wrench"></i> Ubah</span>
                  </div>

                  <div className="card horizontal card-cart">
                    <div className="row m-bottom0">
                      { editUserOrder ? (
                        <>
                          <div className="input-field col-sm-6 m-bottom0">
                            <label className="active" for="icon_prefix">Nama Penerima</label>
                            <input placeholder="Nama Penerima" type="text" className="form-control" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                          </div>
                          <div className="input-field col-sm-6 m-bottom0">
                            <label className="active" for="icon_prefix">No.Hp</label>
                            <input placeholder="No.Hp" type="text" className="form-control" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)} />
                          </div>
                          {/* <div className="input-field col-sm-6 m-bottom0">
                            <label className="active" for="icon_prefix">Email</label>
                            <input placeholder="Email" type="text" className="form-control" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)} />
                          </div> */}
                          { mitra === false ? (
                            <div className="input-field col-sm-12 m-bottom0 m-top0 p-top0 select-none-ui">
                              <label className='select-label'>Pilih Metode Pembayaran</label>
                              <select id="sel" className='form-control' value={customerPaymentMethod} onChange={e => setCustomerPaymentMethodLoad(e.target.value)} >
                                <option value="Transfer">Transfer</option>
                                <option value="COD">COD (Bayar di Tempat)</option>
                              </select>
                            </div>
                          ) : null}
                        </>
                      ) : null } 

                      { editUserOrder ? (
                        <div className="input-field col-sm-12 autocomplete-field">
                          <label className="active" for="icon_prefix">
                            Kecamatan Tujuan Pengiriman
                          </label>
                          <ReactSearchAutocomplete
                            items={districts}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}
                            />
                        </div>
                      ) : member && dropship === false && mitra === true ? (
                        <div className='col-sm-12'>
                          <div className='well full-address-box' style={{minHeight: 60}}>
                            <div>
                              <b>{member.full_name} {member.phone1}</b>
                            </div>
                            <div>
                              {member.email}
                            </div>
                            <div>
                              {member.address} {member.district} {member.city} {member.province}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='col-sm-12'>
                          <div className='well full-address-box' style={{minHeight: 60}}>
                            <div>
                              <b>{customerName} {customerPhone ? customerPhone : null}</b>
                            </div>
                            <div>
                              {customerEmail}
                            </div>
                            <div>
                              {address} {fullAddress}
                            </div>
                          </div>
                        </div>
                      )}

                      { editUserOrder ? (
                        <div className="input-field col-sm-12 m-bottom0">
                          <br/>
                          <label className="active" for="icon_prefix">Alamat Lengkap Pengiriman</label>
                          <input placeholder="Alamat Lengkap" type="text" className="form-control" value={address} onChange={e => setAddress(e.target.value)} />
                        </div>
                      ) : null } 
                    </div>
                  </div>
                  
                  <div className="card horizontal card-cart">
                    <b>Detail Pemesanan</b>
                    { mitra === true && member && member.group_user_name !== null ? (
                      <label className='btn-right-label2'>
                        <input type="checkbox" defaultChecked={dropship} onChange={() => onSelectDropshiper(!dropship)} />
                        <span> Kirim Sebagai Dropshiper</span>
                      </label>
                    ) : null }
                  </div>
                  
                  { editUserSender ? (
                    <div className="card horizontal card-cart">
                      <div className="row m-bottom0">
                        <>
                          <div className="input-field col-sm-6 m-bottom0">
                            <label className="active" for="icon_prefix">Nama Pengirim</label>
                            <input placeholder="Nama Pengirim" type="text" className="form-control" value={storeName} onChange={e => setStoreName(e.target.value)} />
                          </div>
                          <div className="input-field col-sm-6 m-bottom0">
                            <label className="active" for="icon_prefix">No.Hp Pengirim</label>
                            <input placeholder="No.Hp Pengirim" type="text" className="form-control" value={storeNumber} onChange={e => setStoreNumber(e.target.value)} />
                          </div>
                          <div className="input-field col-sm-12 m-bottom0 m-top0 p-top0 select-none-ui">
                            <label className='select-label'>Pilih Metode Pembayaran</label>
                            <select id="sel" className='form-control' value={customerPaymentMethod} onChange={e => setCustomerPaymentMethodLoad(e.target.value)} >
                              <option value="Transfer">Transfer</option>
                              <option value="COD">COD (Bayar di Tempat)</option>
                            </select>
                          </div>    
                        </>
                      </div>
                    </div>
                  ) : null
                  }

                  { mitra === true && member ? (
                    <div className="card horizontal card-cart">
                      <div className="row m-bottom0">
                        <>
                          <div className="input-field col-sm-12 m-bottom0 m-top0 p-top0 select-none-ui">
                            <label className='select-label'>Pilih Metode Pembayaran</label>
                            <select id="sel" className='form-control' value={customerPaymentMethod} onChange={e => setCustomerPaymentMethodLoad(e.target.value)} >
                              <option value="Transfer">Transfer</option>
                              <option value="COD">COD (Bayar di Tempat)</option>
                            </select>
                          </div>    
                        </>
                      </div>
                    </div>
                  ) : null
                  }

                  { orderItems ? (
                    orderItems.map((oi) => {
                      return (
                        <div key={oi.id} className="card horizontal card-cart card-flex">
                          <div className="card-image">
                            <img src={oi.product.image_url}/>
                          </div>
                          <div className="card-stacked">
                            <div className="card-content row">
                              <div className='col-sm-12'>
                                <div className='card-product'>{oi.product_name}</div>
                              </div>
                              <div className='col-sm-6'>
                                { oi.variant_name_1 ? (
                                  <div className='card-variant'>
                                    <label className='label label-default' onClick={e => modalEditCart(oi.id)}>Varian {oi.variant_name_1} {oi.variant_name_2}</label>
                                  </div>
                                ) : null}
                                {
                                  mitra ? (
                                    <div className='card-variant'>
                                      Harga Beli 
                                      {oi.discount > 0 ? (<span className='discount-price'> {currencyFormat(parseInt(oi.store_price*oi.quantity)+parseInt(oi.discount))} </span>) : null}
                                      <b className=''> {currencyFormat(parseInt(oi.store_price*oi.quantity))} </b>
                                    </div>
                                  ) : null
                                }
                                {
                                  dropship || mitra === false ? (
                                    <div className='card-variant'>
                                      Jual Konsumen <br/>
                                      {oi.discount_customer > 0 ? (<span className='discount-price'> {currencyFormat(parseInt(oi.sell_price*oi.quantity)+parseInt(oi.discount_customer))} </span>) : null}
                                      <b className=''>{currencyFormat(parseInt(oi.sell_price*oi.quantity))}</b>
                                    </div>   
                                  ) : null
                                }
                                <div className='card-variant'>Berat <b>{parseInt(oi.product.weight*oi.quantity)} gr</b></div>

                                {
                                  mitra ? (
                                    <>
                                      { oi.member_point > 0 ? (
                                        <div className='card-variant'>
                                          <label className='label label-point'>{oi.member_point} POIN</label>
                                        </div>
                                      ) : null }
                                      { oi.member_cashback > 0 ? (
                                        <div className='card-variant'>
                                          <label className='label label-cashback'>{currencyFormat(oi.member_cashback)} Cashback</label>
                                        </div>
                                      ) : null }
                                    </>
                                  ) : null
                                }
                              </div>
                              
                              <div className='col-sm-6'>
                                <div className='btn btn-delete' onClick={e => deleteItem(oi.id)}>Hapus</div>
                                <div className='btn btn-edit' onClick={e => modalEditCart(oi.id)}>Ubah</div>
                                <div className='input-group small'>
                                  <div className='btn btn-default btn-increament' onClick={e => IncrementItem(oi.quantity, oi.id)}>+</div>
                                  <input placeholder="Jumlah" type="number" min='1' className="form-control input-border text-center input-increament" value={oi.quantity} onChange={e => updateQuantity(e.target.value, oi.id)} />
                                  <div className='btn btn-default btn-increament' onClick={e => DecreaseItem(oi.quantity, oi.id)}>-</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : null }

                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col-sm-4 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 select-none-ui">
                        <select className='form-control' value={expeditionId} onChange={e => setGetServiceExpedition(e.target.value)} >
                          { expeditions ? (
                            expeditions.map((ex) => {
                              return (
                                <option key={ex.id} value={ex.id} >{ex.name.toUpperCase() }</option>
                              )
                            })
                          ) : null }
                        </select>
                      </div>
                      <div className="input-field col-sm-8 m-bottom0 m-top0 p-top0 m-bottom0 p-bottom0 select-none-ui">
                        <select className='form-control' value={expeditionService} onChange={e => setServiceExpedition(e.target.value)} >
                          { expeditionServices ? (
                            expeditionServices.map((ex) => {
                              return (
                                <option key={ex.service + '-' + ex.price.toString()} value={ex.service + '-' + ex.price.toString()}>{ex.description} ({ex.service}) {currencyFormat(ex.price)}</option>
                              )
                            })
                          ) : null }
                        </select>
                      </div>  

                      { expeditionName && expeditionName.includes('Marketplace') ? (
                        <div className="input-field col s12 m-bottom0 m-bottom0 p-bottom0">
                          <input placeholder="Masukan Resi Marketplace" type="text" className="form-control" value={expeditionNumber} onChange={e => setExpeditionNumber(e.target.value)} />
                        </div>
                      ) : null }

                      { expeditionName && expeditionName.includes('Pickup') ? (
                        <div className="input-field col s12 m-bottom0 m-bottom0 p-bottom0">
                          <input placeholder="Masukan Cara Pickup" type="text" className="form-control" value={expeditionNumber} onChange={e => setExpeditionNumber(e.target.value)} />
                        </div>
                      ) : null }
                    </div>
                  </div>
                  
                  <div className="card horizontal card-cart">
                    <div className='row m-bottom0'>
                      <div className="input-field col-6 m-top0 p-top0 p-bottom0 m-bottom0">
                        <b>Transaksi Anda</b>
                      </div>
                      <div className="input-field col-6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                        <b>Rincian Harga</b>
                      </div>
                    </div>
                  </div>

                  {
                    mitra ? (
                      <div className="card horizontal card-cart">
                        <div className='row m-bottom0'>
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0">
                            Subtotal Produk Anda
                          </div>
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat(order.store_price ? order.store_price : 0)}
                          </div>
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0">
                            Subtotal Ongkos Kirim
                          </div>
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat((parseInt(shippingPrice)))}
                          </div>
                          { dropship === true && customerPaymentMethod === 'COD' || customerPaymentMethod === 'COD' ? (
                            <>
                              <div class="input-field col-6 m-top0 p-top0 p-bottom0">
                                Biaya Penanganan COD
                              </div>
                              <div class="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                                {currencyFormat( (parseInt(feeCOD)) )}
                              </div>
                            </>
                          ) : null}
                          { order.subsidi > 0 ? (
                            <>
                              <div className="input-field col-6 m-top0 p-top0 p-bottom0">
                                Potongan Subsidi Ongkir
                              </div>
                              <div className="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                                - {currencyFormat(order.subsidi)}
                              </div>
                            </>
                          ) : null }
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0">
                            TOTAL PEMBAYARAN ANDA
                          </div>
                          <div className="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                            <b>{currencyFormat(parseInt(order.store_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)) - parseInt(subsidi))}</b>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  { mitra === false || (dropship && member && member.group_user_name !== null) ? (
                    <>
                      <div className="card horizontal card-cart">
                        <div className='row m-bottom0'>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0 m-bottom0">
                            <b>Detail Tagihan Ke Konsumen</b>
                          </div>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0 m-bottom0 text-right">
                            <b>Rincian Harga</b>
                          </div>
                        </div>
                      </div>
                      <div className="card horizontal card-cart">
                        <div className='row m-bottom0'>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0">
                            Subtotal Produk Konsumen
                          </div>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat(order.sell_price)}
                          </div>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0">
                            Subtotal Ongkos Kirim
                          </div>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0 text-right">
                            {currencyFormat((parseInt(shippingPrice)))}
                          </div>

                          { order.subsidi > 0 && member === undefined ? (
                            <>
                              <div className="input-field col-6 m-top0 p-top0 p-bottom0">
                                Potongan Subsidi Ongkir
                              </div>
                              <div className="input-field col-6 m-top0 p-top0 p-bottom0 text-right">
                                - {currencyFormat(order.subsidi)}
                              </div>
                            </>
                          ) : null }

                          { dropship === true && customerPaymentMethod === 'COD' || mitra === false && customerPaymentMethod === 'COD' ? (
                            <>
                              <div class="input-field col-sm-6 m-top0 p-top0 p-bottom0">
                                Biaya Penanganan COD
                              </div>
                              <div class="input-field col-sm-6 m-top0 p-top0 p-bottom0 text-right">
                                {currencyFormat( (parseInt(feeCOD)) )}
                              </div>
                            </>
                          ) : null}
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0">
                            TOTAL {customerPaymentMethod.toUpperCase()}
                          </div>
                          <div className="input-field col-sm-6 m-top0 p-top0 p-bottom0 text-right">
                            { order.subsidi > 0 && member === undefined ? (
                              <b>{currencyFormat(parseInt(order.sell_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code))-parseInt(subsidi))}</b>
                            ) : (
                              <b>{currencyFormat(parseInt(order.sell_price)+(parseInt(shippingPrice)+parseInt(feeCOD)+parseInt(order.uniq_code)))}</b>
                            ) }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="row add-chart-seaction">
                    <div className="col-sm-12">
                      { spinnerCheckout ? (
                        <button className='btn btn-info btn-chart'>
                          <center><Dots color='#fff' /></center>
                        </button>
                      ) : (
                        <button className='btn btn-info btn-chart' onClick={checkoutAct} style={{background: (currentCompany?.company_color3)}}> Buat Pesanan</button>
                      ) }
                    </div>
                  </div>
                  
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-8 col-sm-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  { spinner ? (
                    <div className='col-md-12'>
                      <div className='loadLoading'><Dots color='#444' /></div>
                    </div>
                  ) : (
                    <>
                      {
                        data.map((u) => {
                          let price = u.sell_price;
                          let priceSell = u.sell_price;
                          
                          if(u.product_type == 'price_on_variant' && u.product_variants && u.product_variants.filter(o => o.parent_id === null).length > 0){
                            let varian_sku = u.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
                            if(u.product_prices && u.product_prices.length > 0){
                              let pPrice = u.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : 0) && o.product_variant_id === varian_sku.id )[0]
                              if(pPrice !== undefined){
                                price = pPrice.sell_price;
                              }else{
                                price = varian_sku.sell_price;
                              }
                              priceSell = varian_sku.sell_price;
                            }
                          }else{
                            if(u.product_prices && u.product_prices.length > 0){
                              let pPrice = u.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : 0) && o.product_variant_id === null)[0]
                              if(pPrice !== undefined){
                                price = pPrice.sell_price;
                              }
                            }
                          }
                          return (
                            <div className='col-sm-3' key={u.id} style={{marginBottom: 10}}>
                              <div className="cover-image">
                                <img src={u.image_url} />
                                <div className='cover-stock'>
                                  Stok : <b>{u.stock}</b>
                                </div>
                                <div className='add-cart-button' style={{background: (currentCompany.company_color1)}} onClick={(e) => toggle(u.id)}>
                                  <i className='fa fa-plus'></i>
                                </div>
                              </div>
                              <div>
                                <b>{u.title}</b>
                                { mitra === true ? (
                                  <div style={{marginBottom: 5}}>
                                    <small>Harga User </small><br/>
                                    { u.discount > 0  ? (
                                      <span className='cover-discount'>
                                        <b>{u.discount}%</b>
                                      </span>
                                    ) : null}
                                    {u.discount > 0 ? (<>&nbsp;<span className='discount-price'>{currencyFormat(price)}</span>&nbsp;</>) : null} {currencyFormat(parseInt(price)-(parseInt(price)*parseInt(u.discount)/100))}
                                  </div>
                                ) : null}
                                <div style={{marginBottom: 5}}>
                                  <small>Harga Konsumen </small><br/>
                                  { u.discount_customer > 0  ? (
                                    <span className='cover-discount'>
                                      <b>{u.discount_customer}%</b>
                                    </span>
                                  ) : null}
                                  {u.discount_customer > 0 ? (<>&nbsp;<span className='discount-price'>{currencyFormat(priceSell)}</span>&nbsp;</>) : null} {currencyFormat(parseInt(priceSell)-(parseInt(priceSell)*parseInt(u.discount_customer)/100))}
                                </div>
                                
                                { u.cashback > 0 ? (
                                  <div>
                                    <small>Cashback <b>{currencyFormat(u.cashback)}</b></small>
                                  </div>
                                  ) : null
                                }
                                { u.subsidi > 0 ? (
                                  <div>
                                    <small>Potongan Ongkir <b>{currencyFormat(u.subsidi)}</b></small>
                                  </div>
                                  ) : null
                                }
                                { u.staff_commision > 0 ? (
                                  <div>
                                    <small>Komisi Closing CS <b>{currencyFormat(u.staff_commision)}</b></small>
                                  </div>
                                  ) : null
                                }
                              </div>
                        
                            </div>
                          )
                        })
                      }
                    </>
                  )}
                  {paging ? (
                    <div class='col-sm-12'>
                      <div onClick={loadMoreData} class='load-more-btn'>
                        {loadMore ? (
                          <center><Dots color='#444' /><br/></center>
                        ) : null}
                        SELANJUTNYA
                      </div>
                    </div>
                  ) : null}
                </div>
              </CardBody>
              {/* <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className='text-center'>Action</th>
                    <th scope="col">Nama Produk</th>
                    { mitra === true ? (
                      <th scope="col">Harga User</th>
                    ) : null}
                    <th scope="col">Harga Konsumen</th>
                    <th scope="col">Stok</th>
                    <th scope="col">Poin</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="5" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {
                        data.map((u) => {
                          let price = u.sell_price;
                          let priceSell = u.sell_price;
                          
                          if(u.product_type == 'price_on_variant' && u.product_variants && u.product_variants.filter(o => o.parent_id === null).length > 0){
                            let varian_sku = u.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id)[0]
                            if(u.product_prices && u.product_prices.length > 0){
                              let pPrice = u.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : 0) && o.product_variant_id === varian_sku.id )[0]
                              if(pPrice !== undefined){
                                price = pPrice.sell_price;
                              }else{
                                price = varian_sku.sell_price;
                              }
                              priceSell = varian_sku.sell_price;
                            }
                          }else{
                            if(u.product_prices && u.product_prices.length > 0){
                              let pPrice = u.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : 0) && o.product_variant_id === null)[0]
                              if(pPrice !== undefined){
                                price = pPrice.sell_price;
                              }
                            }
                          }
                          return (
                            <tr key={u.id}>
                              <td className='text-center'>
                                <div className='add-cart-button' style={{background: (currentCompany.company_color1)}} onClick={(e) => toggle(u.id)}><i className='fa fa-plus'></i></div>
                              </td>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                    <img alt="..." src={u.image_url} />
                                  </a>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      <small>{u.code}</small>
                                      <br/>
                                      {u.title}
                                      <br/>
                                      <small>{u.brand_name}</small>
                                      
                                      { u.cashback > 0 ? (
                                        <>
                                          <br/>
                                          <small>Cashback <b>{currencyFormat(u.cashback)}</b></small>
                                        </>
                                        ) : null
                                      }
                                      { u.subsidi > 0 ? (
                                        <>
                                          <br/>
                                          <small>Potongan Ongkir <b>{currencyFormat(u.subsidi)}</b></small>
                                        </>
                                        ) : null
                                      }
                                      { u.staff_commision > 0 ? (
                                        <>
                                          <br/>
                                          <small>Komisi Closing CS <b>{currencyFormat(u.staff_commision)}</b></small>
                                        </>
                                        ) : null
                                      }
                                    </span>
                                  </Media>
                                </Media>  
                              </th>
                              { mitra === true ? (
                                <td>
                                  { u.discount > 0 ? (
                                    <>
                                      <span>Disc <b>{u.discount}%</b></span>
                                      <br/>
                                    </>
                                    ) : null
                                  }
                                  {u.discount > 0 ? (<><span className='discount-price'>{currencyFormat(price)}</span><br/></>) : null} {currencyFormat(parseInt(price)-(parseInt(price)*parseInt(u.discount)/100))}
                                </td>
                              ) : null}
                              <td>
                                { u.discount_customer > 0 ? (
                                  <>
                                    <span>Disc <b>{u.discount_customer}%</b></span>
                                    <br/>
                                  </>
                                  ) : null
                                }
                                {u.discount_customer > 0 ? (<><span className='discount-price'>{currencyFormat(priceSell)}</span><br/></>) : null} {currencyFormat(parseInt(priceSell)-(parseInt(priceSell)*parseInt(u.discount_customer)/100))}
                              </td>
                              <td>
                                {u.stock}
                              </td>
                              <td>
                                {u.point}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </>
                  )}
                </tbody>
                </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null} */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ShopIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : 'red',
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_verified: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}