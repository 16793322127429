import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { NodeBuilderFlags } from 'typescript';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const CheckShippingIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [districts, setDistricts] = useState([]);
  const [data, setData] = useState([]);
  const [originId, setOriginId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [weight, setWeight] = useState(0);

  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Data Ongkir Belum Ditelusuri');

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setOriginId(item.id)
  }

  const handleOnSelect2 = (item) => {
    setDistrictId(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
      })
  }

  const checkPost = () => {    
    setSpinner(true);
    setErrorMessage('');
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { origin_id: originId, district_id: districtId, weight: parseInt(weight) } };

    API.get(`check_shipping_price`, config)
      .then(res => {
        setSpinner(false);
        const data = res.data.report;
        setData(data);
      })
      .catch(error => {
        setSpinner(false);
        setData([]);
        if(error === undefined){
          setErrorMessage('Ongkir Tidak Dapat Ditelusuri');
        }else{
          setErrorMessage('Ongkir Tidak Dapat Ditelusuri');
        }
        console.log(error.response);
      })
  }

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-md-4">            
            <Card className="shadow mb-20">
              <CardHeader>Cek Ongkos Kirim</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className="input-field col-sm-12 autocomplete-field">
                    <label className="active" for="icon_prefix">
                      Kecamatan Asal Pengiriman
                    </label>
                    <ReactSearchAutocomplete
                      items={districts}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      formatResult={formatResult}
                      />
                  </div>
                  <div className='col-sm-12'>
                    <br/>
                    <Label className='label-form-group'>Masukan Berat</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          Gr
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='number'
                        placeholder="Masukan Berat"
                        value={weight} onChange={e => setWeight(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-field col-sm-12 autocomplete-field">
                    <label className="active" for="icon_prefix">
                      Kecamatan Tujuan Pengiriman
                    </label>
                    <ReactSearchAutocomplete
                      items={districts}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect2}
                      formatResult={formatResult}
                      />
                  </div>
                  <div className='col-sm-12'>
                    <br/>
                    <Button onClick={(e) => checkPost(e)} className='btn btn-info full-width' style={{background: (currentCompany?.company_color3)}}>CEK ONGKIR</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-md-8">
            <Card className="shadow mb-20">
              <CardHeader>Hasil Cek Ongkos Kirim</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className='col-sm-12'>
                    { spinner ? (
                        <div className='text-center'>
                          <div className='loadLoading'><Dots color='#444' /></div>
                        </div>
                      ) : (
                        <Table className="align-items-center table-flush" responsive>
                          <tbody>
                            {
                              data.map((u) => {
                                return (
                                  <tr key={u.id}>
                                    <td>
                                      <i className="ni ni-delivery-fast text-blue"></i> {u.name} {u.service} ({u.description})
                                      <br/>
                                      Ongkir <b>{u.price}</b> Estimasi Hari <b>{u.day}</b>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      )
                    }

                    {
                      errorMessage ? (
                        <p><small>{errorMessage}</small></p>
                      ) : null
                    }
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  );
};

export default CheckShippingIndex;
