import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = ({company}) => {
  
  return (
    <>
      <footer className="py-2">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="12">
              <div className="copyright text-center text-xl-left" style={{color: 'white'}}>
                © Copyright {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="#"
                  target="_blank"
                >
                </a>
              </div>
            </Col>
            {/* <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    href="#"
                    target="_blank"
                  >
                    Tentang Kami
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.creative-tim.com/presentation?ref=adr-auth-footer"
                    target="_blank"
                  >
                    Kontak Kami
                  </NavLink>
                </NavItem>
              </Nav>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
