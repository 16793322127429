import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";
import QrReader from 'react-qr-reader';
import QRCode from "react-qr-code";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";
import { NodeBuilderFlags } from 'typescript';

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ScanCode = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [data, setData] = useState();
  const [scanCode, setScanCode] = useState('');
  const [statusShipping, setStatusShipping] = useState('retur');

  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollectionShipping = [
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const checkPost = (code) => {    
    setSpinner(true);
    setErrorMessage('');
    let _code = code ? code : scanCode

    const config = { headers: { Authorization: `Bearer ${token}` } };
    const fd = new FormData();
    fd.append('status_shipping', statusShipping);
    
    API.put(`orders/${_code}`, fd, config )
      .then(res => {
        setSpinner(false);
        const data = res.data.data;
        setData(data);
      })
      .catch(error => {
        setSpinner(false);
        setData();
        if(error === undefined){
          setErrorMessage('Kode QRCode Tidak Ditemukan');
        }else{
          setErrorMessage('Kode QRCode Tidak Ditemukan');
        }
        console.log(error.response);
      })
  }

  const handleScan = (data) => {
    if (data && data.length > 0) {
      setScanCode(data);
      checkPost(data);
    }else if(data && data.length === 0){
      alert('Kode QRCode Tidak Ditemukan')
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-md-4">            
            <Card className="shadow mb-20">
              <CardHeader>Scan QRCode</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className="input-field col-sm-12">
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={(result) => {
                        handleScan(result)
                      }}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className='col-12'>
                    <br/>
                    <Label className='label-form-group'>Kode Pesanan</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <Input
                        type='number'
                        placeholder="Masukan Kode Pesanan"
                        value={scanCode} onChange={e => setScanCode(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div class='col-12'>
                    <Label className='label-form-group'>Ubah Status Pengiriman</Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-truck" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                        <option value=''>Ubah Status Pengiriman</option>
                        {
                          statusCollectionShipping.map((c) => {
                            return (
                              <option key={c.value} value={c.value}>{c.name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                  <div className='col-sm-12'>
                    <br/>
                    <Button onClick={(e) => checkPost(e)} className='btn btn-info full-width' style={{background: (currentCompany?.company_color3)}}>SUBMIT</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-md-8">
            <Card className="shadow mb-20">
              <CardHeader>Hasil Cek Scan QRCode</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className='col-sm-12'>
                    { spinner ? (
                        <div className='text-center'>
                          <div className='loadLoading'><Dots color='#444' /></div>
                        </div>
                      ) : data ? (
                        <>
                          <div style={{marginBottom: 20, width: '100%', color: '#24b324'}}>
                            Pesanan Berhasil Diubah Menjadi <b>{data.status_shipping_txt}</b> !
                          </div>
                          <div className='order-print-box box-page-break'>
                            <div className='order-print-box-header'>
                              <div className='row'>
                                <div className='col-4'><img src={currentCompany ? currentCompany.company_logo_url : ''} style={{height: 30}}/></div>
                                <div className='col-4'>
                                  <div style={{fontSize: 20, fontWeight: 'bold'}}>{data.expedition_name.toUpperCase()} {data.expedition_service}</div>
                                </div>
                                <div className='col-4'>
                                  <img src={data.expedition ? data.expedition.image_url : ''} style={{height: 30}}/>
                                </div>
                              </div>
                            </div>
                            <div className='order-print-box-body'>
                              {data.expedition_number ? (
                                <div className='order-print-box-code'>
                                  <b>NO.RESI {data.expedition_number ? data.expedition_number : ''}</b>
                                </div>
                              ) : (
                                <div className='order-print-box-code'>
                                  <b>NO.PESANAN {data.code ? data.code : ''}</b>
                                </div>
                              ) }
                              
                              <div className='row' style={{paddingBottom:10}}>
                                <div className='col-6' style={{textAlign: 'left'}}>
                                  <b>Pengirim : {data.store_name}</b> <br/>{data.store_number}
                                  <hr style={{marginBottom:10, marginTop:10}}/>
                                  <b>Penerima : {data.customer_name}</b> <br/>{data.customer_phone}
                                  <br/>
                                  {data.customer_address} {data.customer_district}, {data.customer_city}, {data.customer_province}
                                </div>
                                <div className='col-6' style={{textAlign: 'left'}}>
                                  <center>
                                    <QRCode value={data.code} size='130'/>
                                  </center>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-6'>
                                  <div className='order-print-box-code'>
                                    {data.customer_city}
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <div className='order-print-box-code'>
                                    {data.customer_district}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-6'>
                                  <div className='order-print-box-code'>
                                    {data.customer_payment_method === 'COD' ? 'Tagihan COD' : 'Pembayaran'}
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <div className='order-print-box-code'>
                                    {data.customer_payment_method === 'COD' ? (
                                      <b>{currencyFormat((parseInt(data.store_price)+parseInt(data.total_shipping_price)+parseInt(data.uniq_code)-parseInt(data.subsidi)))}</b>
                                    ) : currencyFormat(0)}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-6' style={{textAlign: 'left'}}>
                                  <b>No.Pesanan: {data.code}</b>
                                  <br/>
                                  Berat: {data.expedition_weight}gr
                                  <br/>
                                  {
                                    data.order_detail ? (
                                    data.order_detail.map((oi) => {
                                      return(
                                        <div>
                                          {oi.quantity} Qty - {oi.product_name}
                                        </div>
                                      )
                                    })
                                  ) : null
                                }
                                </div>
                                <div className='col-6' style={{textAlign: 'left'}}>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null
                    }

                    {
                      errorMessage ? (
                        <p><small>{errorMessage}</small></p>
                      ) : null
                    }
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  );
};

export default ScanCode;
