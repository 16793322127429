import { Link } from "react-router-dom";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import API from '../../utils/api';

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AdminNavbar = (props) => {
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");

  const actLogout = () => {
    const config = { headers: { Authorization: `Bearer ${token}`} };

    API.delete('sessions', config )
      .then(res => {
        ReactSession.remove("token");
        ReactSession.remove("current_user");
        ReactSession.remove("current_company");
        ReactSession.remove("current_order_id");
        history.push("/auth/login");
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-uppercase d-none d-lg-inline-block h1-section"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="Avatar"
                      src={currentUser ? currentUser.avatar_url : ''}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {currentUser ? currentUser.full_name : ''}
                    </span>

                    { currentUser ? (
                      <>
                        <br/>
                        <small>
                          {currentUser ? currentUser.role_access_txt : ''}
                        </small>
                      </>
                    ) : null }
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Profil Saya</span>
                </DropdownItem> */}
                <DropdownItem to="/admin/account" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Pengaturan Akun</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {actLogout()}}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
