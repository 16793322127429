import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PurchaseNew = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [variants, setVariants] = useState([]);
  const [childVariants, setChildVariants] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('pending');
  const [description, setDescription] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountId, setAccountId] = useState('');
  const [toAccountId, setToAccountId] = useState('');
  const [toAccountName, setToAccountName] = useState('');
  const [billPrice, setBillPrice] = useState(0);
  const [billShippingPrice, setBillShippingPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [purchaseItems, setPurchaseItems] = useState([]);
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [titleItem, setTitleItem] = useState('');
  const [quantityItem, setQuantityItem] = useState('');
  const [billPriceItem, setBillPriceItem] = useState('');
  const [billShippingPriceItem, setBillShippingPriceItem] = useState('');
  const [brandId, setBrandId] = useState('');
  const [productId, setProductId] = useState('');
  const [productVariantId, setProductVariantId] = useState('');
  const [productChildVariantId, setProductChildVariantId] = useState('');
  const [productName, setProductName] = useState('');
  const [productVariantName, setProductVariantName] = useState('');
  const [productChildVariantName, setProductChildVariantName] = useState('');

  const [changeToAccount, setChangeToAccount] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(modal === false){
      setTitleItem('');
      setProductId('');
      setProductVariantId('');
      setProductChildVariantId('');
      setProductName('');
      setProductVariantName('');
      setProductChildVariantName('');
      loadProducts();
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Proses', 'value': 'process'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const categories = [
    {'name':'Pengadaan Produk', 'value': 'product_price'},
    {'name':'Pengadaan Packaging', 'value': 'packaging_price'},
    {'name':'Bayar Ongkir', 'value':'shipping_price'},
    {'name':'Lain-Lain', 'value':'other'}
  ]


  const loadData = () => {    
    if(id){
      const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'company,purchase_items' }};
      API.get(`purchases/${id}`, config)
        .then(res => {
          const data = res.data.data;
          console.log(res.data)
          setData(data)
          setStatus(data.status);
          setSpinner(false);

          setCode(data.code);
          setTitle(data.title);
          setCategory(data.category);
          setStatus(data.status_payment);
          setDescription(data.description);
          setTransactionDate(data.transaction_date);
          setAccountId(data.account_id);
          setAccountName(data.account_name);
          setToAccountName(data.to_account_name);
          setToAccountId(data.to_account_id);
          setBillPrice(data.bill_price);
          setBillShippingPrice(data.bill_shipping_price);
          setDownPayment(data.down_payment);
          setDataLoad();
          setImageSrc(data.image_url);
          setImageFile('');
          setPurchaseItems(data.purchase_items)
        })
    }
  }

  const setDataLoad = () => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {by_use_payment: true,  by_status: true, pagination: false }};
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
    
    config = { headers: { Authorization: `Bearer ${token}`}, params: { by_status: true, pagination: false }};    
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setAccounts(data);
      })
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('code', code);
    fd.append('title', title);
    fd.append('category', category);
    fd.append('status_payment', status);
    fd.append('description', description);
    fd.append('transaction_date', transactionDate);
    fd.append('account_name', accountName);
    fd.append('account_id', accountId);
    fd.append('to_account_id', toAccountId);
    fd.append('to_account_name', toAccountName);
    fd.append('bill_price', billPrice);
    fd.append('bill_shipping_price', billShippingPrice);
    fd.append('down_payment', downPayment);
    fd.append('include', 'purchase_items');

    if(id){
      API.put(`purchases/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          setData(dt);
          setId(dt.id)
          setCode(dt.code);
          setTitle(dt.title);
          setCategory(dt.category);
          setStatus(dt.status_payment);
          setDescription(dt.description);
          setTransactionDate(dt.transaction_date);
          setAccountId(dt.account_id);
          setAccountName(dt.account_name);
          setToAccountId(dt.to_account_id);
          setToAccountName(dt.to_account_name);
          setBillPrice(dt.bill_price);
          setBillShippingPrice(dt.bill_shipping_price);
          setDownPayment(dt.down_payment);
          setDataLoad();
          setImageSrc(dt.image_url);
          setImageFile('');

          setSpinnerSave(false);
          loadData('');

          setPurchaseItems(data.purchase_items)
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post(`purchases`, fd, config )
        .then(res => {
          const dt = res.data.data;
          setData(dt);
          setId(dt.id);
          setCode(dt.code);
          setTitle(dt.title);
          setCategory(dt.category);
          setStatus(dt.status_payment);
          setDescription(dt.description);
          setTransactionDate(dt.transaction_date);
          setAccountId(dt.account_id);
          setToAccountId(dt.to_account_id);
          setAccountName(dt.account_name);
          setToAccountName(dt.to_account_name);
          setBillPrice(dt.bill_price);
          setBillShippingPrice(dt.bill_shipping_price);
          setDownPayment(dt.down_payment);
          setDataLoad();
          setImageSrc(dt.image_url);
          setImageFile('');

          setSpinnerSave(false);
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }

  }

  const actPostItem = () => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('purchase_id', id);
    fd.append('title', titleItem);
    fd.append('quantity', quantityItem);
    fd.append('bill_price', billPriceItem);
    fd.append('bill_shipping_price', billShippingPriceItem);
    fd.append('brand_id', brandId);
    fd.append('product_id', productId);
    fd.append('product_variant_id', productVariantId);
    fd.append('product_child_variant_id', productChildVariantId);
    fd.append('include', 'company,purchase_items');

    API.post(`purchase_items`, fd, config )
      .then(res => {
        const dt = res.data.data;
        setTitleItem('');
        setQuantityItem('');
        setBillPriceItem('');
        setBillShippingPriceItem('');
        setBrandId('');
        setProductId('');
        setProductVariantId('');
        setProductChildVariantId('');
        setProductName('');
        setProductVariantName('');
        setProductChildVariantName('');
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })

  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.delete(`purchase_items/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const onChangeToAccount = () => {
    setChangeToAccount(!changeToAccount)
    if(!changeToAccount){
      setToAccountName('')
    }else{
      setToAccountId('')
    }
  }

  const loadProducts = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, pagination: false, include: 'product_variants' }};
    API.get(`products`, config)
      .then(res => {
        const data = res.data.data;
        setProducts(data);
      })
  }

  const loadVariants = (product_id) => {
    let _product_id = product_id.includes(';') ? product_id.split(";")[0] : ''
    let _product_name = product_id.includes(';') ? product_id.split(";")[1] : ''
    setProductId(_product_id);
    setProductName(_product_name);
    setTitleItem(_product_name);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_product_id: _product_id, pagination: false }};    
    API.get(`product_variants`, config)
      .then(res => {
        const data = res.data.data;
        setVariants(data);
      })
  }

  const loadChildVariants = (variant_id) => {
    let _variant_id = variant_id.includes(';') ? variant_id.split(";")[0] : ''
    let _variant_name = variant_id.includes(';') ? variant_id.split(";")[1] : ''
    setProductVariantId(_variant_id);
    setProductVariantName(_variant_name);
    setTitleItem(`${productName}-${_variant_name}`);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_parent_id: _variant_id, pagination: false }};    
    API.get(`product_variants`, config)
      .then(res => {
        const data = res.data.data;
        setChildVariants(data);
      })
  }

  const checkProductChildVariantId = (variant_id) => {
    let _variant_id = variant_id.includes(';') ? variant_id.split(";")[0] : ''
    let _variant_name = variant_id.includes(';') ? variant_id.split(";")[1] : ''
    setProductChildVariantId(_variant_id);
    setProductChildVariantName(_variant_name);
    setTitleItem(`${productName}-${productVariantName}-${_variant_name}`);
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    loadData('');
    setDataLoad();

  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Tentukan Produk</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-12'>
                  <label className='label-form-group'>Pilih Produk</label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input type="select" onChange={e => loadVariants(e.target.value)}>
                      <option value=''>Pilih Produk</option>
                      { products ? (
                          products.map((c) => {
                            return (
                              <option key={c.id} value={`${c.id};(${c.code}) ${c.title}`}>({c.code}) {c.title}</option>
                            )
                          })
                        ) : null
                      }
                    </Input>
                  </InputGroup>
                </div>
                { productId && variants && variants.length > 0 ? (
                  <div className='col-12'>
                    <label className='label-form-group'>Parent Varian</label>
                    <InputGroup className="input-group-alternative mb-20">
                      <Input type="select" onChange={e => loadChildVariants(e.target.value)}>
                        <option value=''>Pilih Varian</option>
                        {
                          variants.filter(v => v.parent_id === null).map((c) => {
                            return (
                              <option key={c.id} value={`${c.id};${c.variant_name}`}>{c.variant_name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) : null }
                { productId && productVariantId && childVariants && childVariants.length > 0 ? (
                  <div className='col-12'>
                    <label className='label-form-group'>Parent Varian</label>
                    <InputGroup className="input-group-alternative mb-20">
                      <Input type="select" onChange={e => checkProductChildVariantId(e.target.value)}>
                        <option value=''>Pilih Varian</option>
                        {
                          childVariants.map((c) => {
                            return (
                              <option key={c.id} value={`${c.id};${c.variant_name}`}>{c.variant_name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) : null }
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={(e) => toggle(e)}>Simpan</Button>
        </ModalFooter>
      </Modal>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>
                      FAKTUR {data.code}
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-12'>
                        <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                          {errorMessage}
                        </Alert>
                      </div>
                      <div className='col-12'>
                        <Label className='label-form-group'>Kategori</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-list" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                            <option value=''>Pilih Kategori</option>
                            {
                              categories.map((c) => {
                                return (
                                  <option key={c.value} value={c.value}>{c.name}</option>
                                )
                              })
                            }
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Kode Faktur</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-flag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Kode Faktur"
                            value={code} onChange={e => setCode(e.target.value)}
                          />
                        </InputGroup>
                      </div>  
                      <div className='col-6'>
                        <Label className='label-form-group'>Status</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-star" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                            <option value=''>Pilih Status</option>
                            {
                              statusCollectionPayment.map((c) => {
                                return (
                                  <option key={c.value} value={c.value}>{c.name}</option>
                                )
                              })
                            }
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Judul</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-coins" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Judul"
                            value={title} onChange={e => setTitle(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Keterangan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            type="textarea"
                            placeholder="Keterangan"
                            value={description} onChange={e => setDescription(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Nilai Tagihan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="Nilai Tagihan"
                            value={billPrice} onChange={e => setBillPrice(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-6'>
                        <Label className='label-form-group'>Nilai Ongkir</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="Nilai Ongkir"
                            value={billShippingPrice} onChange={e => setBillShippingPrice(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      {/* <div className='col-6'>
                        <Label className='label-form-group'>Nilai Dibayarkan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              RP
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='number'
                            placeholder="0"
                            value={downPayment} onChange={e => setDownPayment(e.target.value)}
                          />
                        </InputGroup>
                      </div> */}
                      <div className='col-12'>
                        <Label className='label-form-group'>Tanggal Transaksi</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <DatePicker id= "example-datepicker" value={transactionDate} onChange={e => setTransactionDate(e)} />
                        </InputGroup>
                      </div>

                      <div className='col-12'>
                        <Label className='label-form-group'>Dibayar Dari</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-credit-card" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="select" value={accountId} onChange={e => setAccountId(e.target.value)}>
                            <option value=''>Pilih No.Rekening</option>
                            {
                              paymentNumbers.map((c) => {
                                return (
                                  <option key={c.id} value={c.id}>{c.name+'-'+c.code}</option>
                                )
                              })
                            }
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-8'>
                            <Label className='label-form-group'>Tujuan Pembayaran</Label>
                          </div>
                          <div className='col-4' style={{textAlign: 'right', color: 'black'}}>
                            <Label className='label-form-group' onClick={(e) => onChangeToAccount(!changeToAccount)} style={{cursor: 'pointer'}}>Pilih Akun</Label>
                          </div>
                        </div>
                        {
                          changeToAccount ? (
                            <InputGroup className="input-group-alternative mb-20">
                              <Input type="select" value={toAccountId} onChange={e => setToAccountId(e.target.value)}>
                                <option value=''>Pilih</option>
                                {
                                  accounts.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>{c.name+'-'+c.code}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          ) : (
                          <InputGroup className="input-group-alternative mb-20">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-credit-card" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Tujuan Rekening Pembayaran"
                              value={toAccountName} onChange={e => setToAccountName(e.target.value)}
                            />
                          </InputGroup>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div class='avatar-user avatar-user-sidebar' style={{marginTop: 25}}>
                      <ImageUpload
                        handleImageSelect={handleImageSelect}
                        imageSrc={imageSrc}
                        setImageSrc={setImageSrc}
                        style={{
                          width: 120,
                          height: 120
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <br/>
                    { spinnerSave ? (
                      <Button color="primary" style={{background: (currentCompany?.company_color3)}}>
                        <Dots color='#fff' />
                      </Button>
                    ) : (
                      <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
                    )}
                  </div>
                </div>

                { id ? (
                  <>
                    <br/>
                    <Table className="align-items-center table-flush" responsive>
                      <tr>
                        <td width='50%'>
                          <b>ITEM</b>
                        </td>
                        <td width='15%'>
                          <b>QTY</b>
                        </td>
                        <td width='20%'>
                          <b>SATUAN HARGA</b>
                        </td>
                        <td width='15%'>
                          <b>SUBTOTAL</b>
                        </td>
                      </tr>
                      {
                        purchaseItems.map((item) => {
                          return(
                            <tr>
                              <td>
                                <span className='pointer' onClick={(e) => deletePost(item.id)}>
                                  <i className='fa fa-trash'></i>
                                </span> {item.title}
                              </td>
                              <td>
                                {item.quantity}
                              </td>
                              <td>
                                {numberFormat(item.bill_price)}
                              </td>
                              <td>
                                <b>{numberFormat(parseInt(item.bill_price)*parseInt(item.quantity))}</b>
                              </td>
                            </tr>
                          )
                        })
                      }

                      <tr>
                        <td>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText onClick={(e) => toggle()} className='pointer'>
                                <i className="ni ni-fat-add" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Item"
                              value={titleItem} onChange={e => setTitleItem(e.target.value)}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup className="input-group-alternative">
                            <Input
                              type='number'
                              placeholder="Qty"
                              value={quantityItem} onChange={e => setQuantityItem(e.target.value)}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup className="input-group-alternative">
                            <Input
                              type='number'
                              placeholder="0"
                              value={billPriceItem} onChange={e => setBillPriceItem(e.target.value)}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup className="input-group-alternative">
                            <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => actPostItem()} className='pull-right full-width'>
                              <i class="fas fa-plus"></i>
                            </Button>
                          </InputGroup>
                        </td>
                      </tr>

                      {
                        data.bill_total_price ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>TOTAL</b>
                            </td>
                            <td>
                              <b>{numberFormat(data.bill_price)}</b>
                            </td>
                          </tr>
                        ) : null
                      }

                      {
                        data.bill_shipping_price ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>ONGKOS KIRIM</b>
                            </td>
                            <td>
                              <b>{numberFormat(data.bill_shipping_price)}</b>
                            </td>
                          </tr>
                        ) : null
                      }

                      {/* {
                        data.down_payment ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>DP</b>
                            </td>
                            <td>
                              <b>- {numberFormat(data.down_payment)}</b>
                            </td>
                          </tr>
                        ) : null
                      } */}

                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>SISA PEMBAYARAN</b>
                        </td>
                        <td>
                          <b>{numberFormat(parseInt(data.bill_total_price)-parseInt(data.down_payment))}</b>
                        </td>
                      </tr>
                    </Table>
                  </>
                ) : null }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PurchaseNew;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}