import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import AuthFooter from "components/Footers/AuthFooter.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { Helmet } from 'react-helmet';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
  mainContent,
  Container
} from "reactstrap";

const Login = () => {
  const history = useHistory();
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const [domain, setDomain] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState({});
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadData = () => {
    const config = { params: {include: ''} };

    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
        ReactSession.set("current_company", data);
      })
  }

  const actLogin = () => {
    setSpinnerSave(true);
    API.post('sessions/dashboard', {identifier: identifier, password: password, domain: domain, include: ''} )
      .then(res => {
        setSpinnerSave(false);
        ReactSession.set("token", res.data.meta.token);
        ReactSession.set("current_user", res.data.data);
        history.push("/admin/index");
      })
      .catch(error => {
        setSpinnerSave(false);
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Login Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token && currentUser){
      history.push("/admin/index")
    }
    loadData('');
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Dashboard'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.avatar_url} />
          <link rel="icon" href={company.avatar_url} />
        </Helmet>
      ) : null}

      <div className="header py-7 py-lg-8 p-b-0">
        <Container>
          <div className="header-body text-center mb-4">
            <Row className="justify-content-center">
              <Col lg="4" md="7">
                {
                  company ? (
                    <img
                        alt="..."
                        style={{width:'90%'}}
                        src={
                          company.company_logo_url
                        }
                      />
                  ) : (
                    <img
                        alt="..."
                        style={{width:'90%'}}
                        src="https://onlinestore-web.s3.ap-southeast-1.amazonaws.com/os2.png"
                      />
                  )
                }
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="4" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-4 py-lg-4">
                <div className="text-center text-muted mb-4">
                  <h1 style={{marginBottom: 30, color: '#444'}}>Login</h1>
                </div>
                <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                  {errorMessage}
                </Alert>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username atau Email"
                        value={identifier} onChange={e => setIdentifier(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Kata Sandi"
                        type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    { spinnerSave ? (
                      <Button className="full-width btn-lg" style={{marginbottom: 20, background: (company ? company.company_color3 : '')}} color="primary" type="button">
                        <Dots color='#fff' />
                      </Button>
                    ) : (
                      <Button className="full-width btn-lg" style={{marginbottom: 20, background: (company ? company.company_color3 : '')}} color="primary" type="button" onClick={actLogin}>
                        MASUK
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AuthFooter company={company}/>
    </>
  );
};

export default Login;
