import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

const WaSettingsIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const categories = [
    { id: 'send_welcome_member', name: 'Selamat Datang Daftar Member'},
    { id: 'send_active_user', name: 'Info Akun Aktif'},
    { id: 'send_upgrade_member', name: 'Upgrade Akun User'},
    { id: 'send_claim', name: 'Klaim Saldo Pencairan'},
    { id: 'send_paid_claim', name: 'Klaim Saldo Terbayar'},
    { id: 'send_order', name: 'Tagihan Pesanan TF'},
    { id: 'send_order_cod', name: 'Tagihan Pesanan COD Konsumen'},
    { id: 'send_order_paid', name: 'Pesanan Terbayar'},
    { id: 'send_order_number_shipping', name: 'Nomor Resi Pesanan Tersedia'},
    { id: 'send_order_received', name: 'Pesanan Diterima'},
    { id: 'send_order_retur', name: 'Pesanan Diretur'}
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setCategory(dt.category);
        setMessage(dt.message);
      }else{
        setId('');
        setCategory('');
        setMessage('');
      }
    }else{
      setId('');
      setCategory('');
      setMessage('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const fd = new FormData();
    fd.append('category', category);
    fd.append('message', message);

    if(id){
      API.put(`wa_settings/${id}`, fd, config )
        .then(res => {
          setId('');
          setCategory('');
          setMessage('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('wa_settings', fd, config )
        .then(res => {
          setId('');
          setCategory('');
          setMessage('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }

  }

  const deletePost = (id) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.delete(`wa_settings/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword  }};
    API.get(`wa_settings`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '' } };
    
    API.get(`wa_settings`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  useEffect(() => {
    // if(token === undefined || currentUser === undefined){
    //   history.push("/")
    // }

    setSearch('');
    loadData('');
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Pesan WA</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-folder" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                  <option value=''>Pilih Kategori</option>
                  { categories ? (
                      categories.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>{c.name}</option>
                        )
                      })
                    ) : null
                  }
                </Input>
              </InputGroup>
              <br/>
              <Label className='label-form-group'>Variabel Pesan</Label>
              <div className='well'>
                Atribut User:<br/>
                <b>{"{username} {email} {full_name} {phone} {package} {package_price}"}</b>
                <br/>
                <br/>
                Atribut Klaim Saldo:<br/>
                <b>{"{claim_code} {claim_price} {claim_payment_number}"}</b>
                <br/>
                <br/>
                Atribut Pesanan:<br/>
                <b>{"{code_order} {order_items} {shipping_price} {uniq_code} {price} {total_price} {subsidi} {virtual_money} {number_shipping} {expedition} {customer_name} {customer_phone} {shipping_address}"}</b>
              </div>
              <br/>
              <Label className='label-form-group'>Isi Pesan Text</Label>
              <InputGroup className="input-group-alternative mb-20">
                <Input
                  type="textarea"
                  placeholder="Isi Pesan"
                  style={{height: 200}}
                  value={message} onChange={e => setMessage(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany?.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-8'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany?.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">ID</th>
                    <th scope="col">Kategori Pesan</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="7" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    { currentUser?.type === 'Adminmaster' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null } 
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>{u.id}</td>
                              <td>{u.category_txt}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default WaSettingsIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}